<template>
    <div>
        <h3
            class="mb-4 text-center font-barlo text-2xl font-medium uppercase tracking-wide text-gray-800"
        >
            Confirm your reservation
        </h3>
        <p class="text-center font-sans text-sm text-gray-500 md:mx-10">
            Etiam ut commodo velit, vel maximus quam. Cras eu suscipit ligula. Nulla pellentesque a
            eros ac varius.
        </p>
        <p
            v-if="commentsAreEnabled"
            class="mt-1 text-center font-sans text-sm text-gray-500 md:mx-10"
        >
            If there's anything we can do to make your reservation even more enjoyable, please let
            us know in the comments below!
        </p>

        <!-- progress indicator goes here -->

        <div class="my-8 flex flex-col lg:flex-row">
            <!-- left column -->
            <div class="flex w-full flex-col lg:w-1/2">
                <ConfirmReservationForm @duration="handleDurationChange" />
            </div>
            <!-- /left column -->

            <!-- right column -->
            <div class="flex w-full flex-col pl-0 pt-8 sm:pt-0 lg:w-1/2 lg:pl-10 lg:pt-6">
                <h3
                    class="mb-4 font-barlo text-lg font-medium uppercase leading-6 tracking-wide text-gray-800"
                >
                    Reservation Details
                </h3>
                <p class="font-sans text-sm text-gray-500">
                    Praesent blandit est nec massa pulvinar hendrerit. In interdum est quis dolor.
                </p>

                <!-- items list -->
                <transition
                    mode="out-in"
                    enter-active-class="transition ease-out duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-in duration-100"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-if="opening" class="my-4 flex w-full justify-center">
                        <ConfirmListItem :opening="opening" :duration="duration" />
                    </div>
                </transition>
                <!-- /items list -->
            </div>
            <!-- /right column -->
        </div>
    </div>
</template>

<script lang="js">
import ConfirmReservationForm from '@/components/reservations/ConfirmReservationForm.vue';
import ConfirmListItem from '@/components/reservations/ConfirmListItem.vue';

export default {
  name: 'ConfirmReservation',
  components: {ConfirmListItem, ConfirmReservationForm},
  data() {
    return {
      duration: '',
    }
  },
  computed: {
    opening() {
      return this.$store.getters['opening/opening'];
    },
    reservableRequiresPayment() {
      return false;
    },
    reservable() {
      return this.opening.reservable;
    },
    commentsAreEnabled() {
      return this.reservable.customFields[0].comments.isEnabled;
    },
  },
  methods: {
    handleDurationChange(duration) {
      this.duration = duration
    }
  }
}
</script>
