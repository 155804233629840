<template>
    <div class="flex w-full flex-row space-x-6 overflow-x-auto">
        <EventListItem
            v-for="(event, idx) in events"
            :key="`event-${idx}`"
            :event="event"
            class="flex-none px-3 py-6 first:pl-0 last:pr-6"
            @event="emitSelectedEvent(event)"
        />
    </div>
</template>

<script lang="js">
import EventListItem from '@/components/events/EventListItem.vue';

export default {
  name:'EventList',
  components: {EventListItem},
  props: {
    events: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  methods: {
    emitSelectedEvent(event) {
      this.$emit('event', event);
    },
  },
}
</script>
