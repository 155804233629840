import Vue from 'vue';
import App from './App.vue';

import './registerServiceWorker';

import router from './router';
import store from './store';

import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';

import VCalendar from 'v-calendar';

import './styles/app.css';
import './styles/v-calendar.css';

import setup from '@/services/AxiosInterceptorsService';

Vue.config.productionTip = false;

setup(store);

Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VCalendar);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
