<template>
    <div
        class="flex w-full transform justify-center overflow-hidden rounded-lg text-left transition-all sm:my-8"
    >
        <div class="flex h-full w-full items-center justify-center px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-center">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10"
                >
                    <svg
                        class="h-6 w-6 animate-spin text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        id="modal-title"
                        class="font-barlo text-sm font-bold uppercase tracking-wider text-gray-500"
                    >
                        {{ message }}...
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
  name: 'LoadingIndicator',
  props: {
    message: {
      type: String,
      required: false,
      default: 'Please wait'
    }
  },
}
</script>
