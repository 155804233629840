import Jsona from 'jsona';

import ReservationService from '@/services/ReservationService';

const dataFormatter = new Jsona();
export const namespaced = true;

export const state = {
    networkError: undefined,
    reservationIsLoading: false,
    reservation: undefined,
};

export const mutations = {
    SET_RESERVATION_LOADING_STATUS(state, payload) {
        state.reservationIsLoading = payload;
    },
    SET_NETWORK_ERROR(state, payload) {
        state.networkError = payload;
    },
    SET_RESERVATION(state, paylod) {
        state.reservation = paylod;
    },
};

export const actions = {
    async saveReservation({ commit }, payload) {
        let { method, reservation } = payload;

        try {
            commit('SET_RESERVATION_LOADING_STATUS', true);

            delete reservation.links;

            const response = await ReservationService.saveReservation(
                method,
                dataFormatter.serialize({
                    stuff: { ...reservation, type: 'reservations' },
                })
            );

            reservation = dataFormatter.deserialize(response.data);

            commit('SET_RESERVATION', reservation);
            commit('SET_RESERVATION_LOADING_STATUS', false);

            return reservation;
        } catch (err) {
            commit('SET_RESERVATION_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async fetchReservation({ commit }, params) {
        try {
            commit('SET_RESERVATION_LOADING_STATUS', true);

            const response = await ReservationService.fetchReservation(params);
            const reservation = dataFormatter.deserialize(response.data);

            commit('SET_RESERVATION', reservation);
            commit('SET_RESERVATION_LOADING_STATUS', false);

            return await reservation;
        } catch (error) {
            commit('SET_RESERVATION', undefined);
            commit('SET_RESERVATION_LOADING_STATUS', false);

            return await Promise.reject(error);
        }
    },
    async setReservation({ commit }, payload) {
        commit('SET_RESERVATION', payload);
    },
    async setReservationLoadingStatus({ commit }, payload) {
        commit('SET_RESERVATION_LOADING_STATUS', payload);
    },
    async setNetworkError({ commit }, payload) {
        commit('SET_NETWORK_ERROR', payload);
    },
};

export const getters = {
    reservation: (state) => {
        return state.reservation;
    },
    reservationIsLoading: (state) => {
        return state.reservationIsLoading;
    },
    networkError: (state) => {
        return state.networkError;
    },
};
