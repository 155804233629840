import client from '@/helpers/axios';

export default {
    async fetchEvents(params) {
        const { pageSize } = params;

        try {
            return await client.get(`/v1/events/?page[size]=${pageSize}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchEvent(params) {
        const { eventId } = params;

        try {
            return await client.get(`/v1/events/${eventId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
