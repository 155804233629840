<template>
    <form>
        <div class="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <!-- duration -->
            <div v-if="shouldShowDurationOptions" class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        :class="[
                            'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                            $v.purchasable.duration.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="duration"
                            :class="[
                                'block text-xs font-medium',
                                $v.purchasable.duration.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                            >How long would you like to play?
                        </label>
                        <select
                            id="duration"
                            v-model.trim="$v.purchasable.duration.$model"
                            name="duration"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.purchasable.duration.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            @change="handleDurationChange($event)"
                        >
                            <option value="" selected>Select</option>
                            <option v-for="option in durationOptions" :key="option" :value="option">
                                {{ option | friendlyDuration }}
                            </option>
                        </select>
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.purchasable.duration.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5 pt-1.5"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="
                                $v.purchasable.duration.$error &&
                                !$v.purchasable.duration.$error.required
                            "
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /duration -->

            <!-- party size -->
            <div v-if="partySizeIsEnabled" class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        :class="[
                            'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                            $v.purchasable.customFields.$each.$iter[0].partySize.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="party-size"
                            :class="[
                                'block text-xs font-medium',
                                $v.purchasable.customFields.$each.$iter[0].partySize.$error
                                    ? 'text-red-700'
                                    : 'text-gray-900',
                            ]"
                            >Party size</label
                        >
                        <input
                            id="party-size"
                            v-model.number="
                                $v.purchasable.customFields.$each.$iter[0].partySize.$model
                            "
                            type="number"
                            name="party-size"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.purchasable.customFields.$each.$iter[0].partySize.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.purchasable.customFields.$each.$iter[0].partySize.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="
                                $v.purchasable.customFields.$each.$iter[0].partySize.$error &&
                                !$v.purchasable.customFields.$each.$iter[0].partySize.$error
                                    .required
                            "
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /party size -->

            <!-- minors -->
            <div v-if="minorsAreEnabled" class="col-span-6 sm:col-span-6">
                <div>
                    <p class="text-sm text-gray-500">Any children under the age of 10?</p>
                    <fieldset class="mt-4">
                        <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            <div class="flex items-center">
                                <input
                                    id="minors-yes"
                                    v-model.trim="
                                        $v.purchasable.customFields.$each.$iter[0].minors.$model
                                    "
                                    name="minors"
                                    type="radio"
                                    value="yes"
                                    :class="[
                                        'h-4 w-4 transition duration-75 ease-linear',
                                        $v.purchasable.customFields.$each.$iter[0].minors.$error
                                            ? 'border-red-300 text-red-600 focus:ring-red-500'
                                            : 'border-gray-300 text-gray-600 focus:ring-gray-600',
                                    ]"
                                />
                                <label
                                    for="minors-yes"
                                    class="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
                                    >Yes</label
                                >
                            </div>
                            <div class="flex items-center">
                                <input
                                    id="minors-no"
                                    v-model="
                                        $v.purchasable.customFields.$each.$iter[0].minors.$model
                                    "
                                    name="minors"
                                    type="radio"
                                    value="no"
                                    :class="[
                                        'h-4 w-4 transition duration-75 ease-linear',
                                        $v.purchasable.customFields.$each.$iter[0].minors.$error
                                            ? 'border-red-300 text-red-600 focus:ring-red-500'
                                            : 'border-gray-300 text-gray-600 focus:ring-gray-600',
                                    ]"
                                />
                                <label
                                    for="minors-no"
                                    class="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
                                    >No</label
                                >
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <!-- /minors -->

            <!-- minors qty -->
            <transition
                enter-active-class="transition-opacity ease-linear duration-75"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-75"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="reservationHasMinors && minorsQtyIsEnabled"
                    class="col-span-6 sm:col-span-6"
                >
                    <div>
                        <div
                            :class="[
                                'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                                $v.purchasable.customFields.$each.$iter[0].minorsQty.$error
                                    ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                    : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                            ]"
                        >
                            <label
                                for="minors-qty"
                                :class="[
                                    'block text-xs font-medium',
                                    $v.purchasable.customFields.$each.$iter[0].minorsQty.$error
                                        ? 'text-red-700'
                                        : 'text-gray-900',
                                ]"
                                >How many minors?</label
                            >
                            <input
                                id="minors-qty"
                                v-model.number="
                                    $v.purchasable.customFields.$each.$iter[0].minorsQty.$model
                                "
                                type="number"
                                step="1"
                                min="0"
                                name="minors-qty"
                                :class="[
                                    'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                    $v.purchasable.customFields.$each.$iter[0].minorsQty.$error
                                        ? 'text-red-900 placeholder-red-300'
                                        : 'text-gray-900 placeholder-gray-300',
                                ]"
                            />
                            <transition
                                enter-active-class="transition-opacity ease-linear duration-75"
                                enter-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition-opacity ease-linear duration-75"
                                leave-class="opacity-100"
                                leave-to-class="opacity-0"
                            >
                                <div
                                    v-if="
                                        $v.purchasable.customFields.$each.$iter[0].minorsQty.$error
                                    "
                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                >
                                    <svg
                                        class="h-5 w-5 text-red-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </transition>
                        </div>
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <p
                                v-if="
                                    $v.purchasable.customFields.$each.$iter[0].minorsQty.$error &&
                                    !$v.purchasable.customFields.$each.$iter[0].minorsQty.$error
                                        .required
                                "
                                key="required"
                                class="mt-2 text-sm font-medium text-red-600"
                            >
                                This field is required
                            </p>
                        </transition>
                    </div>
                </div>
            </transition>
            <!-- /minors qty -->

            <!-- comments -->
            <div v-if="commentsAreEnabled" class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        :class="[
                            'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                            $v.purchasable.comments.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="comments"
                            :class="[
                                'block text-xs font-medium',
                                $v.purchasable.comments.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                            >Comments</label
                        >
                        <textarea
                            id="comments"
                            v-model.trim="
                                $v.purchasable.customFields.$each.$iter[0].comments.$model
                            "
                            rows="6"
                            name="comments"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.purchasable.comments.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            placeholder="Any comments or special requests?"
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.purchasable.comments.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="
                                $v.purchasable.comments.$error &&
                                !$v.purchasable.comments.$error.required
                            "
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /comments -->

            <div class="col-span-6 flex justify-between sm:col-span-6 sm:mb-8">
                <button
                    type="button"
                    class="rounded-md border border-gray-300 bg-white px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-gray-700 shadow-sm transition duration-75 ease-linear hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                    @click="cancel"
                >
                    Cancel
                </button>
                <button
                    data-cy="submit-button"
                    type="submit"
                    :disabled="shouldDisableSubmit"
                    :class="[
                        'ml-4 inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60 md:ml-4',
                        orderIsLoading
                            ? 'cursor-not-allowed hover:bg-gray-600'
                            : 'hover:bg-gray-700',
                    ]"
                    @click.prevent="addToBag"
                >
                    <svg
                        v-if="orderIsLoading"
                        key="loading-indicator"
                        data-cy="loading-indicator"
                        class="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <span
                        v-if="orderIsLoading"
                        key="processing"
                        class="font-barlo font-medium uppercase tracking-wider"
                        >Please wait...</span
                    >
                    <span v-else key="text" class="font-barlo font-medium uppercase tracking-wider"
                        >ADD TO BAG</span
                    >
                </button>
            </div>
        </div>
    </form>
</template>

<script lang="js">
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import {
  requiredIf,
  minValue,
} from 'vuelidate/lib/validators';

dayjs.extend(duration);

export default {
  name: 'ConfirmReservationForm',
  filters: {
    friendlyDuration(value) {
      if (!value) {
        return '';
      }

      const hours = dayjs.duration(value, 'minutes').asHours();

      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    },
  },
  data() {
    return {
      purchasables: [],
      durationOptions: [],
      purchasable: {
        activityId: '',
        reservableId: '',
        type: 'RESERVATION',
        status: 'CREATED',
        dateTimeStart: '',
        dateTimeEnd: '',
        duration: '',
        price: '',
        qty: '',
        customFields: [
          {
            partySize: this.$store.getters['search/partySize']
                ? this.$store.getters['search/partySize']
                : '',
            minors: undefined,
            minorsQty: '',
            comments: '',
          },
        ],
      }
    }
  },
  computed: {
    reservable() {
      return this.opening.reservable;
    },
    location() {
      return this.$store.getters['location/location'];
    },
    opening() {
      return this.$store.getters['opening/opening'];
    },
    order() {
      return this.$store.getters['order/order'];
    },
    shouldShowDurationOptions() {
      return this.location.durations[0].reservations.minimumDuration !== this.location.durations[0].reservations.maximumDuration && this.opening.duration !== this.location.durations[0].reservations.minimumDuration;
    },
    shouldDisableSubmit() {
      return this.orderIsLoading || this.$v.$invalid;
    },
    partySizeIsEnabled() {
      return this.reservable.customFields[0].partySize.isEnabled;
    },
    minorsAreEnabled() {
      return this.reservable.customFields[0].minors.isEnabled;
    },
    minorsQtyIsEnabled() {
      return this.reservable.customFields[0].minorsQty.isEnabled;
    },
    commentsAreEnabled() {
      return this.reservable.customFields[0].comments.isEnabled;
    },
    partySize() {
      return this.$store.getters['search/partySize'];
    },
    orderIsLoading() {
      return this.$store.getters['order/orderIsLoading'];
    },
    reservationHasMinors() {
      return this.$v.purchasable.customFields.$each.$iter[0].minors.$model === 'yes';
    },
  },
  async created() {
    await this.prepopulateConfirmForm();
  },
  methods: {
    async prepopulateConfirmForm() {
      await this.generateReservationDurationOptions();

      if (this.partySize) {
        this.$v.purchasable.customFields.$each.$iter[0].partySize.$model = this.partySize;
      }

      await this.$store.dispatch('search/setPartySize', this.partySize);

      if (this.location.durations[0].reservations.minimumDuration ===
          this.location.durations[0].reservations.maximumDuration) {
        this.$v.purchasable.duration.$model = this.location.durations[0].reservations.maximumDuration;

        await this.$emit('duration', this.location.durations[0].reservations.maximumDuration);
      }
    },
    async addToBag() {
      this.$v.purchasable.$touch();

      if (!this.$v.$invalid) {
        let order = this.order;

        if (!order) {
          const orderPayload = {
            order: {
              organizationId: this.opening.organizationId,
              locationId: this.opening.locationId,
              status: 'CREATED',
              createdBy: 'GUEST',
              purchasables: [],
            },
            method: 'POST',
          }

          order = await this.$store.dispatch('order/saveOrder', orderPayload);
        }

        const reservationPayload = {
          reservation: {
            organizationId: this.order.organizationId,
            locationId: this.order.locationId,
            reservableId: this.opening.reservable.id,
            orderId: this.order.id,
            activities: [],
            status: 'CREATED',
            dateTimeStart: this.opening.dateTimeStart,
            dateTimeEnd: this.opening.dateTimeEnd,
            duration: (this.$v.purchasable.duration.$model) ? this.$v.purchasable.duration.$model : this.location.durations[0].reservations.minimumDuration,
            price: '',
            capabilities: [],
            customFields: [{
              partySize: this.$v.purchasable.customFields.$each.$iter[0].partySize.$model,
              minors: this.$v.purchasable.customFields.$each.$iter[0].minors.$model,
              minorsQty: this.$v.purchasable.customFields.$each.$iter[0].minorsQty.$model,
              comments: this.$v.purchasable.customFields.$each.$iter[0].comments.$model,
            }],
          },
          method: 'POST',
        }

        this.opening.reservable.activities.forEach((activity) => {
          reservationPayload.reservation.activities.push(activity.id);
        });

        const reservation = await this.$store.dispatch('reservation/saveReservation', reservationPayload);

        await this.$store.dispatch('cart/addItem', reservation);

        order.purchasables.push({
          type:'RESERVATION',
          id: reservation.id,
        });

        await this.$store.dispatch('order/saveOrder', {
          order,
          method: 'PATCH',
        });

        await this.$store.dispatch(
            'progress/setCurrentStep',
            'CONTINUING'
        );
      }
    },
    async handleDurationChange(event) {
      await this.$emit('duration', event.target.value);
    },
    async generateReservationDurationOptions() {
      const minValue = this.opening.minimumDuration;
      const maxValue = this.opening.maximumDuration;
      const interval = this.opening.interval;
      const duration = this.opening.duration;

      for (var i = minValue; i <= maxValue; i += interval) {
        if (i <= duration) {
          this.durationOptions.push(i);
        }
      }
    },
    async cancel() {
      await this.$store.dispatch('progress/setCurrentStep', 'BROWSING');
      await this.$store.dispatch('opening/setOpening', undefined);
      await this.$store.dispatch('search/setPartySize', '');
      await this.$store.dispatch('reservation/setReservation', undefined);
    }
  },
  validations: {
    purchasable: {
      comments: {},
      duration: {
        required: requiredIf(function () {
          return this.location.durations[0].reservations.minimumDuration !== this.location.durations[0].reservations.maximumDuration && this.opening.duration !== this.location.durations[0].reservations.minimumDuration;
        }),
      },
      customFields: {
        $each: {
          partySize: {
            minValue: minValue(1),
            required: requiredIf(function () {
              return this
                  .reservable.customFields[0].partySize.isEnabled;
            }),
          },
          minors: {
            required: requiredIf(function () {
              return this.opening.reservable.customFields[0].minors.isEnabled;
            }),
          },
          minorsQty: {
            required: requiredIf(function () {
              return this.opening.reservable.customFields[0].minorsQty.isEnabled &&
                  this.$v.purchasable.customFields.$each.$iter[0].minors.$model === 'yes';
            }),
          },
          comments: {},
        },
      },
    },
  },
}
</script>
