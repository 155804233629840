export const namespaced = true;

export const state = {
    partySize: '',
};

export const mutations = {
    SET_PARTY_SIZE(state, payload) {
        state.partySize = payload;
    },
};

export const actions = {
    async setPartySize({ commit }, payload) {
        commit('SET_PARTY_SIZE', payload);
    },
};

export const getters = {
    partySize: (state) => {
        return state.partySize;
    },
};
