import Jsona from 'jsona';

import LocationService from '@/services/LocationService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    locationIsLoading: false,
    location: localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location'))
        : undefined,
};

export const mutations = {
    SET_LOCATION_LOADING_STATUS(state, payload) {
        state.locationIsLoading = payload;
    },
    SET_LOCATION(state, payload) {
        state.location = payload;

        if (payload) {
            localStorage.setItem('location', JSON.stringify(payload));
        } else {
            localStorage.removeItem('location');
        }
    },
};

export const actions = {
    async fetchLocation({ commit, dispatch }, params) {
        try {
            commit('SET_LOCATION_LOADING_STATUS', true);

            const response = await LocationService.fetchLocation(params);
            const location = dataFormatter.deserialize(response.data);

            dispatch('activity/setActivities', location.activities, {
                root: true,
            });

            commit('SET_LOCATION', location);
            commit('SET_LOCATION_LOADING_STATUS', false);

            return location;
        } catch (error) {
            commit('SET_LOCATION', undefined);
            commit('SET_LOCATION_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    setLocation({ commit }, payload) {
        commit('SET_LOCATION', payload);
    },
};

export const getters = {
    location: (state) => {
        return state.location;
    },
    locationIsLoading: (state) => {
        return state.locationIsLoading;
    },
};
