import client from '@/helpers/axios';

export default {
    async saveOrder(method, order) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/orders/${order.data.id}`, order);
            } catch (err) {
                return await err;
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/orders`, order);
            } catch (err) {
                return await err;
            }
        }
    },
    async fetchOrder(params) {
        const { orderId } = params;

        try {
            return await client.get(`/v1/orders/${orderId}?include=reservations`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
