<template>
    <div>
        <!-- reservation search -->
        <ReservationSearchForm v-if="currentStep === 'BROWSING'" class="mb-6" />
        <!-- /reservation search -->

        <!-- walk-in availability -->
        <div v-if="currentStep === 'BROWSING'" class="pb-6">
            <hr class="mx-2 mb-6 text-gray-500 md:mx-10" />
            <h3
                class="mb-4 text-center font-barlo text-2xl font-medium uppercase tracking-wide text-gray-800"
            >
                Walk-in Availability
            </h3>

            <p
                v-if="!openingsAreLoading && thereAreOpenings && locationHasWalkInInstructions"
                class="text-center font-sans text-sm text-gray-500 md:mx-10"
                v-html="location.walkInInstructions"
            ></p>

            <!-- availability feedback -->
            <transition
                enter-active-class="transition delay-200 ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <LoadingIndicator v-if="openingsAreLoading" :message="'Checking availability'" />
            </transition>

            <!-- default exception message -->
            <transition
                enter-active-class="transition delay-200 ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="!openingsAreLoading && !thereAreOpenings && exceptionMessage === ''"
                    class="mt-6"
                >
                    <div class="mb-2 flex h-full flex-col items-center justify-center"></div>
                    <p class="text-center font-sans text-sm text-gray-500 md:mx-20">
                        <strong>Oops! We're currently closed.</strong><br />
                        Please check back later, or schedule some time with us&nbsp;above.<br />See
                        you soon!
                    </p>
                </div>
            </transition>
            <!-- /default exception message -->

            <transition
                enter-active-class="transition delay-200 ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="!openingsAreLoading && !thereAreOpenings && exceptionMessage"
                    class="mt-6"
                >
                    <p
                        class="text-center font-sans text-sm text-gray-500 md:mx-20"
                        v-html="exceptionMessage"
                    ></p>
                </div>
            </transition>

            <transition
                enter-active-class="transition delay-200 ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <OpeningList
                    v-if="currentStep === 'BROWSING' && thereAreOpenings"
                    class="mx-5 my-4 md:mx-20"
                    :openings="openings"
                ></OpeningList>
            </transition>
            <!-- /openings -->
        </div>
        <!-- /walk-in availability -->

        <!-- confirming -->
        <transition
            enter-active-class="transition ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <ConfirmReservation v-if="currentStep === 'CONFIRMING'" />
        </transition>
        <!-- /confirming -->
    </div>
</template>

<script lang="js">
import ReservationSearchForm from '@/components/reservations/ReservationSearchForm.vue';
import ConfirmReservation from '@/components/reservations/ConfirmReservation.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import OpeningList from '@/components/reservations/OpeningList.vue';

export default {
  name: 'ReservationsComponent',
  components: {ConfirmReservation, LoadingIndicator, OpeningList, ReservationSearchForm},
  computed: {
    location() {
      return this.$store.getters['location/location'];
    },
    currentStep() {
      return this.$store.getters['progress/currentStep'];
    },
    openingsAreLoading() {
      return this.$store.getters['opening/openingsAreLoading'];
    },
    openings() {
      return this.$store.getters['opening/openings'];
    },
    thereAreOpenings() {
      return !this.openingsAreLoading && this.openings.length > 0;
    },
    locationHasWalkInInstructions() {
      return this.location.walkInInstructions !== '';
    },
    exceptionMessage() {
      return this.$store.getters['opening/exceptionMessage'];
    },
  },
}
</script>
