import client from '@/helpers/axios';

export default {
    async fetchOpenings(params) {
        const { locationId, beginning, activityId, partySize, pageSize } = params;

        try {
            return await client.get(
                `/v1/openings/?locationId=${locationId}&beginning=${beginning}&activityId=${activityId}&partySize=${partySize}&page[size]=${pageSize}&include=reservable`
            );
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
