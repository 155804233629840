import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import * as constantContact from '@/store/modules/constantContact';
import * as application from '@/store/modules/application';
import * as reservation from '@/store/modules/reservation';
import * as location from '@/store/modules/location';
import * as progress from '@/store/modules/progress';
import * as activity from '@/store/modules/activity';
import * as opening from '@/store/modules/opening';
import * as search from '@/store/modules/search';
import * as panel from '@/store/modules/panel';
import * as order from '@/store/modules/order';
import * as timer from '@/store/modules/timer';
import * as event from '@/store/modules/event';
import * as cart from '@/store/modules/cart';
import * as tab from '@/store/modules/tab';

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        constantContact,
        application,
        reservation,
        location,
        progress,
        activity,
        opening,
        search,
        panel,
        order,
        timer,
        event,
        cart,
        tab,
    },
});
