import Cookies from 'js-cookie';

export const namespaced = true;

export const state = {
    accessToken: '',
};

export const mutations = {};

export const actions = {};

export const getters = {
    accessToken: (state) => {
        return state.accessToken || Cookies.get('ccAccessToken');
    },
};
