export const namespaced = true;

export const state = {
    activeTab: 'RESERVATIONS',
};

export const mutations = {
    SET_TAB: function (state, payload) {
        state.activeTab = payload;
    },
};

export const actions = {
    setActiveTab({ commit }, payload) {
        commit('SET_TAB', payload);
    },
};

export const getters = {
    activeTab: (state) => {
        return state.activeTab;
    },
};
