<template>
    <div class="flex flex-wrap justify-center">
        <OpeningListItem
            v-for="(opening, idx) in openings"
            :key="`opening-${idx}`"
            :opening="opening"
        />
    </div>
</template>

<script lang="js">
import OpeningListItem from '@/components/reservations/OpeningListItem.vue';

export default {
  name: 'OpeningList',
  components: {OpeningListItem},
  props: {
    openings: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
}
</script>
