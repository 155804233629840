import Vue from 'vue';

let ClickAway = Vue.directive('click-away', {
    bind(el, { value }) {
        if (typeof value !== 'function') {
            console.warn(`Expected a function, got ${value}`); // eslint-disable-line no-console

            return;
        }

        document.addEventListener('click', (e) => el.contains(e.target) || value());
    },
});

export { ClickAway };
