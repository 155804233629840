<template>
    <div id="app">
        <div>
            <!-- book now button -->
            <div class="flex flex-col items-center justify-center">
                <button
                    data-cy="book-button"
                    type="button"
                    :class="[
                        'inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-bold uppercase tracking-wide text-white shadow-sm transition duration-75 ease-linear hover:bg-gray-700  focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2',
                        panelIsOpen ? 'z-auto' : 'z-50',
                    ]"
                    @click="openPanel"
                >
                    BOOK NOW
                </button>
            </div>
            <!-- /book now button -->

            <!-- widget slide over -->
            <WidgetSlideOver :open="panelIsOpen" @close="closePanel" />
            <!-- /widget slide over -->
        </div>
    </div>
</template>

<script lang="js">
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import WidgetSlideOver from '@/components/WidgetSlideOver.vue';

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'App',
  components: {WidgetSlideOver},
  computed: {
    panelStatus() {
      return this.$store.getters['panel/status'];
    },
    panelIsOpen() {
      return this.panelStatus === 'open';
    },
    location() {
      return this.$store.getters['location/location'];
    },
  },
  created() {
    this.fetchLocation();
  },
  methods: {
    async fetchLocation() {
      // TODO: This needs to come from the widget's props or some other attribute from the embedded widget.
      const locationId = (process.env.NODE_ENV === 'production') ? '9c270fba-3b06-42ab-8100-e50aa8658054' : '9c1b69a6-0522-4b6b-8516-f4469df447fb';

      await this.$store.dispatch('location/fetchLocation', {
        locationId,
      });
    },
    async openPanel() {
      await this.$store.dispatch('panel/setStatus', 'open');
      await this.searchForImmediateOpenings();
    },
    async searchForImmediateOpenings() {
      const now = dayjs().tz(this.location.timezone);
      const beginning = now.startOf('hour').add(this.location.durations[0].reservations.interval, 'minutes');

      console.log('beginning', beginning.format('YYYY-MM-DDTHH:mm:ssZ'));

      await this.$store.dispatch('opening/fetchOpenings', {
        locationId: this.location.id,
        beginning: beginning.format('YYYY-MM-DDTHH:mm:ssZ'),
        activityId: (this.selectedActivityId) ? this.selectedActivityId : '',
        pageSize: 100,
      });
    },
    async closePanel() {
      await this.$store.dispatch('panel/setStatus', 'closed');
      await this.$store.dispatch('tab/setActiveTab', 'RESERVATIONS');
      await this.$store.dispatch('opening/reset');
      await this.$store.dispatch('progress/setCurrentStep', 'BROWSING');
      await this.$store.dispatch('search/setPartySize', '');
      await this.$store.dispatch('cart/setItems', []);
      await this.$store.dispatch('order/setOrder', undefined);
      await this.$store.dispatch('reservation/setReservation', undefined);
      await this.$store.dispatch('timer/setTimerVisibility', false);
      await this.$store.dispatch('event/setEvent', undefined);
    }
  }
}
</script>
