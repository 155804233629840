<template>
    <div class="mx-2 mb-8 w-full cursor-pointer last:mb-0 sm:mb-6">
        <div class="flex-start flex items-center">
            <!-- opening -->
            <button
                type="button"
                :class="[
                    'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 font-barlo text-base font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60',
                    opening.confirming
                        ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 disabled:bg-gray-300 disabled:text-gray-700'
                        : 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 disabled:bg-gray-300 disabled:text-gray-700',
                ]"
                @click.prevent="handleSelectedOpening(opening)"
            >
                <template class="flex flex-col">
                    <div class="flex flex-row items-center">
                        <div v-for="(activity, index) in opening.activities" :key="index">
                            <!-- axe throwing -->
                            <svg
                                v-if="activity.name === 'Axe Throwing'"
                                class="mr-3 h-5 w-5 transition duration-200 ease-in-out"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <g
                                        transform="translate(-685.000000, -510.000000)"
                                        class="stroke-current"
                                        :class="[opening.confirming ? 'text-white' : 'text-white']"
                                        stroke-width="1.40350877"
                                    >
                                        <g id="white" transform="translate(685.000000, 511.000000)">
                                            <rect
                                                transform="translate(17.317224, 5.741784) rotate(-315.000000) translate(-17.317224, -5.741784)"
                                                x="16.3815519"
                                                y="4.30903579"
                                                width="1.87134503"
                                                height="2.86549708"
                                                rx="0.935672515"
                                            ></rect>
                                            <rect
                                                transform="translate(6.338461, 16.720548) rotate(-315.000000) translate(-6.338461, -16.720548) "
                                                x="5.40278871"
                                                y="10.1708399"
                                                width="1.87134503"
                                                height="13.0994152"
                                                rx="0.935672515"
                                            ></rect>
                                            <path
                                                d="M18.2865183,9.00321368 L13.204824,14.1702216 C11.2180702,12.1912683 9.71244826,10.94818 8.6879583,10.4409566 C7.66346834,9.93373317 6.13200465,9.65857438 4.09356725,9.61548018 C4.25677978,6.51019882 5.34098307,4.11321512 7.34617713,2.4245291 C9.35137119,0.735843081 11.4725939,-0.0707147956 13.7098453,0.00485547265 C13.7098453,2.25010508 14.0338932,3.90100597 14.681989,4.95755813 C15.3300848,6.0141103 16.5315946,7.36266215 18.2865183,9.00321368 Z"
                                                stroke-linejoin="round"
                                            ></path>
                                            <path
                                                d="M13.7171909,3.03491547 C11.1900428,3.59155581 8.3576748,6.00385832 7.41361562,9.65483714"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <!-- /axe throwing -->
                        </div>
                        <div
                            :class="[
                                'font-sans text-sm font-bold tracking-wide text-white',
                                opening.confirming ? '' : '',
                            ]"
                        >
                            <div>{{ opening.dateTimeStart | friendlyTime(location) }}</div>
                            <div
                                v-if="
                                    location.durations[0].reservations.minimumDuration !==
                                    location.durations[0].reservations.maximumDuration
                                "
                                class="flex items-center font-sans text-xs font-normal tracking-wide text-white"
                            >
                                {{ friendlyDuration }}
                            </div>
                        </div>
                    </div>
                </template>
            </button>
            <!-- /opening -->

            <!-- confirm -->
            <button
                v-if="opening.confirming"
                type="button"
                :class="[
                    'ml-2 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 font-barlo text-base font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60',
                    opening.confirming
                        ? 'bg-gray-400 hover:bg-gray-600 focus:ring-gray-400 disabled:bg-gray-200 disabled:text-gray-600'
                        : 'bg-gray-500 hover:bg-gray-700 focus:ring-gray-500 disabled:bg-gray-300 disabled:text-gray-700',
                ]"
                @click="confirmSelectedOpening(opening)"
            >
                <span class="font-sans text-sm font-bold tracking-wider">CONFIRM</span>
            </button>
            <!-- /confirm -->
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export default {
    name: 'OpeningListItem',
    filters: {
        friendlyTime(value, location) {
            if (!value || !location) {
                return '';
            }

            return dayjs.utc(value).tz(location.timezone).format('h:mm A');
        },
    },
    props: {
        opening: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            isSelected: false,
        };
    },
    computed: {
        activities() {
            return this.opening.activities;
        },
        location() {
            return this.$store.getters['location/location'];
        },
        friendlyDuration() {
            const hours = dayjs.duration(this.opening.duration, 'minutes').asHours();

            return `(${hours} ${hours === 1 ? 'hour' : 'hours'} max)`;
        },
    },
    methods: {
        async handleSelectedOpening(opening) {
            opening.confirming = !opening.confirming;

            opening.interval = this.location.durations[0].reservations.interval;
            opening.minimumDuration = this.location.durations[0].reservations.minimumDuration;
            opening.maximumDuration = this.location.durations[0].reservations.maximumDuration;

            if (opening.confirming) {
                await this.$store.dispatch('opening/setOpening', opening);
            } else {
                await this.$store.dispatch('opening/setOpening', undefined);
            }
        },
        async confirmSelectedOpening(opening) {
            // TODO: make api call to create a reservation for the given dateTimeStart with a status of CREATED.
            await this.$store.dispatch('progress/setCurrentStep', 'CONFIRMING');
            opening.confirming = false;
        },
    },
};
</script>
