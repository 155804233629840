import client from '@/helpers/axios';

export default {
    async saveReservation(method, reservation) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/reservations/${reservation.data.id}`, reservation);
            } catch (err) {
                return await err;
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/reservations`, reservation);
            } catch (err) {
                return await err;
            }
        }
    },
    async fetchReservation(params) {
        const { reservationId } = params;

        try {
            return await client.get(`/v1/reservations/${reservationId}?include=order,reservable`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
