<template>
    <transition
        mode="out-in"
        enter-active-class="transition ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="cartIsNotEmpty"
            class="absolute right-0 top-0 rounded bg-gray-600 px-1 font-barlo text-xs text-white shadow"
        >
            {{ items.length }}
        </div>
    </transition>
</template>
<script lang="js">
export default {
  name: 'ItemsIndicator',
  computed: {
    cartIsNotEmpty() {
      return this.items.length > 0;
    },
    items() {
      return this.$store.getters['cart/items'];
    }
  }
}
</script>
