<template>
    <div class="flex w-full overflow-hidden bg-white shadow sm:rounded-md">
        <div class="flex w-full flex-row items-center px-4 py-4 sm:px-6">
            <div class="flex min-w-0 flex-1 items-center">
                <div class="flex w-full flex-shrink-0 flex-row items-center justify-between">
                    <div>
                        <!-- axe throwing -->
                        <svg
                            v-if="opening.reservable.activities[0].name === 'Axe Throwing'"
                            class="mr-3 h-6 w-6 transition duration-200 ease-in-out"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <g
                                    transform="translate(-685.000000, -510.000000)"
                                    class="stroke-current text-gray-700"
                                    stroke-width="1.40350877"
                                >
                                    <g id="white" transform="translate(685.000000, 511.000000)">
                                        <rect
                                            transform="translate(17.317224, 5.741784) rotate(-315.000000) translate(-17.317224, -5.741784)"
                                            x="16.3815519"
                                            y="4.30903579"
                                            width="1.87134503"
                                            height="2.86549708"
                                            rx="0.935672515"
                                        ></rect>
                                        <rect
                                            transform="translate(6.338461, 16.720548) rotate(-315.000000) translate(-6.338461, -16.720548) "
                                            x="5.40278871"
                                            y="10.1708399"
                                            width="1.87134503"
                                            height="13.0994152"
                                            rx="0.935672515"
                                        ></rect>
                                        <path
                                            d="M18.2865183,9.00321368 L13.204824,14.1702216 C11.2180702,12.1912683 9.71244826,10.94818 8.6879583,10.4409566 C7.66346834,9.93373317 6.13200465,9.65857438 4.09356725,9.61548018 C4.25677978,6.51019882 5.34098307,4.11321512 7.34617713,2.4245291 C9.35137119,0.735843081 11.4725939,-0.0707147956 13.7098453,0.00485547265 C13.7098453,2.25010508 14.0338932,3.90100597 14.681989,4.95755813 C15.3300848,6.0141103 16.5315946,7.36266215 18.2865183,9.00321368 Z"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M13.7171909,3.03491547 C11.1900428,3.59155581 8.3576748,6.00385832 7.41361562,9.65483714"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <!-- /axe throwing -->
                    </div>

                    <!-- activity -->
                    <div class="flex flex-1 flex-col">
                        <p
                            class="font-barlo text-sm font-medium uppercase tracking-wide text-gray-800"
                        >
                            {{ opening.reservable.activities[0].name }}
                        </p>
                        <p class="font-sans text-xs uppercase leading-4 text-gray-500">
                            {{ opening.dateTimeStart | friendlyDate }}<br />
                            {{ opening.dateTimeStart | friendlyTime }} - {{ endTime }}
                        </p>
                        <p
                            v-if="reservableRequiresPayment"
                            class="font-sans text-xs uppercase text-gray-400"
                        >
                            $0.00
                        </p>
                    </div>
                    <!-- /activity -->
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="js">
import dayjs from 'dayjs';

export default {
  name: 'ConfirmListItem',
  filters: {
    friendlyDate(value) {
      if (!value) return '';

      return dayjs(value).format('dddd, MMM D');
    },
    friendlyTime(value) {
      if (!value) return '';

      return dayjs(value).format('h:mm A');
    },
    friendlyDuration(value) {
      if (!value) return '';

      return dayjs.duration(value, 'milliseconds').humanize();
    },
  },
  props: {
    opening: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    duration: {
      type: [Number, String],
      required: true,
      default: '',
    }
  },
  computed: {
    reservableRequiresPayment() {
      return false;
    },
    location() {
      return this.$store.getters['location/location'];
    },
    endTime() {
        const minimumDuration = this.location.durations[0].reservations.minimumDuration
        console.log('minimumDuration:', minimumDuration);

        if (this.opening.duration === minimumDuration) {
          return dayjs.utc(this.opening.dateTimeStart)
              .tz(this.location.timezone)
              .add(minimumDuration, 'minutes')
              .format('h:mm A');
        } else {
          if (this.duration !== '') {
            return dayjs.utc(this.opening.dateTimeStart).tz(location.timezone).add(this.duration, 'minutes').format('h:mm A');
          } else {
            return '';
          }
        }
    }
  }
}
</script>
