import EventService from '@/services/EventService';

export const namespaced = true;

import Jsona from 'jsona';

const dataFormatter = new Jsona();

export const state = {
    events: [],
    event: undefined,
    eventsAreLoading: false,
    eventIsLoading: false,
};

export const mutations = {
    SET_EVENTS(state, payload) {
        state.events = payload;
    },
    SET_EVENT(state, payload) {
        state.event = payload;
    },
    SET_EVENTS_LOADING_STATUS(state, payload) {
        state.eventsAreLoading = payload;
    },
    SET_EVENT_LOADING_STATUS(state, payload) {
        state.eventIsLoading = payload;
    },
};

export const actions = {
    async fetchEvents({ commit }, payload) {
        commit('SET_EVENTS_LOADING_STATUS', true);

        const response = await EventService.fetchEvents(payload);
        const events = dataFormatter.deserialize(response.data);

        commit('SET_EVENTS', events);
        commit('SET_EVENTS_LOADING_STATUS', false);
    },
    setEvent({ commit }, payload) {
        commit('SET_EVENT', payload);
    },
};

export const getters = {
    events: (state) => {
        return state.events;
    },
    event: (state) => {
        return state.event;
    },
    eventsAreLoading: (state) => {
        return state.eventsAreLoading;
    },
    eventIsLoading: (state) => {
        return state.eventIsLoading;
    },
};
