import Jsona from 'jsona';

import OrderService from '@/services/OrderService';

const dataFormatter = new Jsona();
export const namespaced = true;

export const state = {
    networkError: undefined,
    orderIsLoading: false,
    order: undefined,
};

export const mutations = {
    SET_ORDER_LOADING_STATUS(state, payload) {
        state.orderIsLoading = payload;
    },
    SET_NETWORK_ERROR(state, payload) {
        state.networkError = payload;
    },
    SET_ORDER(state, paylod) {
        state.order = paylod;
    },
};

export const actions = {
    async saveOrder({ commit }, payload) {
        let { method, order } = payload;

        try {
            commit('SET_ORDER_LOADING_STATUS', true);

            delete order.links;

            if (order.phoneNumber) {
                order.phoneNumber = order.phoneNumber.replace(/[^+\d]+/g, '');
            }

            const response = await OrderService.saveOrder(
                method,
                dataFormatter.serialize({
                    stuff: { ...order, type: 'orders' },
                })
            );

            order = dataFormatter.deserialize(response.data);

            commit('SET_ORDER', order);
            commit('SET_ORDER_LOADING_STATUS', false);

            return order;
        } catch (err) {
            // const notification = {
            //     type: 'ERROR',
            //     title: 'Uh-oh!',
            //     message: err.message,
            // };
            //
            // dispatch('notification/addNotification', notification, {
            //     root: true,
            // });

            commit('SET_ORDER_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async fetchOrder({ commit }, params) {
        try {
            commit('SET_ORDER_LOADING_STATUS', true);

            const response = await OrderService.fetchOrder(params);
            const order = dataFormatter.deserialize(response.data);

            commit('SET_ORDER', order);
            commit('SET_ORDER_LOADING_STATUS', false);

            return await order;
        } catch (error) {
            commit('SET_ORDER', undefined);
            commit('SET_ORDER_LOADING_STATUS', false);

            return await Promise.reject(error);
        }
    },
    async setOrder({ commit }, payload) {
        commit('SET_ORDER', payload);
    },
    async setOrderLoadingStatus({ commit }, payload) {
        commit('SET_ORDER_LOADING_STATUS', payload);
    },
    async setNetworkError({ commit }, payload) {
        commit('SET_NETWORK_ERROR', payload);
    },
};

export const getters = {
    order: (state) => {
        return state.order;
    },
    orderIsLoading: (state) => {
        return state.orderIsLoading;
    },
    networkError: (state) => {
        return state.networkError;
    },
};
