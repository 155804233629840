<template>
    <div
        class="relative z-0 w-auto flex-1 overflow-y-auto focus:outline-none xl:order-first xl:border-r xl:border-gray-200"
    >
        <div class="flex h-full flex-col items-center justify-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            <div class="flex flex-col items-center justify-center">
                <h3
                    class="space-x-0 font-barlo text-xl font-bold uppercase tracking-wide text-gray-800"
                >
                    Your bag is empty
                </h3>
                <div class="flex flex-col items-center text-center text-gray-800">
                    <p class="mt-1 font-sans text-xs text-gray-500">
                        This is a super-sad moment for everyone.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
 name: 'EmptyCartComponent',
}
</script>
