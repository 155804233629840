<template>
    <li>
        <div class="flex w-full flex-row items-center px-4 py-4 sm:px-6">
            <div class="flex min-w-0 flex-1 items-center">
                <div class="flex w-full flex-shrink-0 flex-row items-center justify-between">
                    <div v-for="(activity, idx) in item.activities" :key="`activity-${idx}`">
                        <!-- axe throwing -->
                        <svg
                            v-if="activity === '9acd3bca-7668-4d30-a368-2c75fb164975'"
                            class="mr-3 h-6 w-6 transition duration-200 ease-linear"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <g
                                    transform="translate(-685.000000, -510.000000)"
                                    class="text-thunder-800 stroke-current"
                                    stroke-width="1.40350877"
                                >
                                    <g id="white" transform="translate(685.000000, 511.000000)">
                                        <rect
                                            transform="translate(17.317224, 5.741784) rotate(-315.000000) translate(-17.317224, -5.741784)"
                                            x="16.3815519"
                                            y="4.30903579"
                                            width="1.87134503"
                                            height="2.86549708"
                                            rx="0.935672515"
                                        ></rect>
                                        <rect
                                            transform="translate(6.338461, 16.720548) rotate(-315.000000) translate(-6.338461, -16.720548) "
                                            x="5.40278871"
                                            y="10.1708399"
                                            width="1.87134503"
                                            height="13.0994152"
                                            rx="0.935672515"
                                        ></rect>
                                        <path
                                            d="M18.2865183,9.00321368 L13.204824,14.1702216 C11.2180702,12.1912683 9.71244826,10.94818 8.6879583,10.4409566 C7.66346834,9.93373317 6.13200465,9.65857438 4.09356725,9.61548018 C4.25677978,6.51019882 5.34098307,4.11321512 7.34617713,2.4245291 C9.35137119,0.735843081 11.4725939,-0.0707147956 13.7098453,0.00485547265 C13.7098453,2.25010508 14.0338932,3.90100597 14.681989,4.95755813 C15.3300848,6.0141103 16.5315946,7.36266215 18.2865183,9.00321368 Z"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M13.7171909,3.03491547 C11.1900428,3.59155581 8.3576748,6.00385832 7.41361562,9.65483714"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <!-- /axe throwing -->

                        <!-- other activities -->
                    </div>

                    <!-- event -->

                    <!-- league -->

                    <!-- activity -->
                    <div
                        v-for="(activity, idx) in item.activities"
                        :key="`cart-item-${idx}`"
                        class="flex flex-1 flex-col"
                    >
                        <p class="font-barlo text-sm font-medium uppercase text-gray-900">
                            {{ activity | friendlyActivityName }}
                        </p>
                        <p class="font-sans text-xs uppercase text-gray-400">
                            {{ item.dateTimeStart | friendlyDate }} @
                            {{ item.dateTimeStart | friendlyTime(location) }}
                        </p>
                        <p
                            v-if="mustPayToProceed"
                            class="font-barlo text-sm uppercase text-gray-900"
                        >
                            {{ $n(item.price / 100, 'currency') }}
                        </p>
                    </div>
                    <!-- /activity -->

                    <!-- event -->
                    <div v-if="event" class="flex flex-1 flex-col">
                        <p class="font-barlo text-sm font-medium uppercase text-gray-900">
                            {{ event.name }}
                        </p>
                        <p class="font-sans text-xs uppercase text-gray-400">
                            {{ item.startDateTime | friendlyDate }} @
                            {{ item.dateTimeStart | friendlyTime(location) }}
                        </p>
                        <p class="mt-1 font-barlo text-sm uppercase text-gray-900">
                            {{ $n(item.price / 100, 'currency') }}
                        </p>
                    </div>
                    <!-- /event -->

                    <!-- remove -->
                    <div class="flex justify-end">
                        <p
                            class="cursor-pointer font-sans text-xs font-bold leading-6 text-gray-400 transition duration-75 ease-linear hover:text-gray-600"
                            @click.prevent="removeItem(item)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </p>
                    </div>
                    <!-- /remove -->
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export default {
    name: 'CartListItem',
    components: {},
    filters: {
        friendlyActivityName(value) {
            if (!value) return '';

            switch (value) {
                case '9c19b9e6-d6aa-4f2f-823e-5f8c45ac70e5':
                    return 'Axe Throwing';
                case '9c1b942d-7c08-4ffa-b004-e0b00c164766':
                    return 'Pickleball';
                default:
                    return 'Activity Name';
            }
        },

        friendlyDate(value) {
            if (!value) return '';
            return dayjs(value).format('ddd, MMM D');
        },
        friendlyTime(value, location) {
            if (!value || !location) {
                return '';
            }

            return dayjs(value).format('h:mm A');
            // return dayjs.utc(value).tz(location.timezone).format('h:mm A');
        },
        friendlyPrice(value) {
            if (!value) return '';
            return `$${value.price.toLocaleString()}`;
        },
    },
    props: {
        item: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {};
    },
    computed: {
        event() {
            if (this.item.type === 'EVENT') {
                return this.item.event;
            }
            return '';
        },
        activity() {
            return 'Pickleball';
            // if (this.item.type === 'RESERVATION') {
            //     return this.item.resource.activity;
            // }
            // return '';
        },
        mustPayToProceed() {
            return false;
        },
        location() {
            return this.$store.getters['location/location'];
        },
    },
    methods: {
        async removeItem(item) {
            if (confirm('Are you sure you want to remove this item?')) {
                await this.$store.dispatch('cart/removeItem', item);

                /**
                 * TODO: if cart is empty, remove the orderID from localStorage.
                 */
            }
        },
    },
};
</script>
