import client from '@/helpers/axios';

export default {
    async fetchLocation(params) {
        const { locationId } = params;

        try {
            return await client.get(`/v1/locations/${locationId}?include=activities`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
