<template>
    <div>
        <img
            width="320"
            height="240"
            :src="event.posterUrl"
            :alt="event.name"
            class="cursor-pointer rounded-sm border-2 border-gray-200 transition duration-150 ease-linear hover:border-gray-600"
            @click="emitSelectedEvent(event)"
        />
        <h1
            class="text-center font-barlo text-lg font-medium uppercase tracking-wide text-gray-800"
        >
            {{ event.title }}
        </h1>
        <h2 class="text-center font-sans text-gray-500 md:mx-10">{{ event.subtitle }}</h2>
    </div>
</template>

<script lang="js">
export default {
  name: 'EventListItem',
  props: {
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  methods: {
    emitSelectedEvent(event) {
      this.$emit('event', event);
    },
  }
}
</script>
