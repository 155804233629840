var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"flex h-full flex-col space-y-5"},[_c('div',{staticClass:"col-span-6 sm:col-span-6"},[_c('div',[_c('div',{class:[
                    'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                    _vm.$v.ticket.qty.$error
                        ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                        : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                ]},[_c('label',{class:[
                        'block text-xs font-medium',
                        _vm.$v.ticket.qty.$error ? 'text-red-700' : 'text-gray-900',
                    ],attrs:{"for":"qty"}},[_vm._v("Quantity")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.$v.ticket.qty.$model),expression:"$v.ticket.qty.$model",modifiers:{"number":true}}],class:[
                        'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                        _vm.$v.ticket.qty.$error
                            ? 'text-red-900 placeholder-red-300'
                            : 'text-gray-900 placeholder-gray-300',
                    ],attrs:{"id":"qty","type":"number","name":"qty","step":"1","min":"1","autocomplete":"off","placeholder":"1","required":""},domProps:{"value":(_vm.$v.ticket.qty.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.ticket.qty, "$model", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('transition',{attrs:{"enter-active-class":"transition-opacity ease-linear duration-75","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transition-opacity ease-linear duration-75","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[(_vm.$v.ticket.qty.$error)?_c('div',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"},[_c('svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])]):_vm._e()])],1),_c('transition',{attrs:{"enter-active-class":"transition-opacity ease-linear duration-75","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transition-opacity ease-linear duration-75","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[(_vm.$v.ticket.qty.$error && !_vm.$v.ticket.qty.$error.required)?_c('p',{key:"required",staticClass:"mt-2 text-sm font-medium text-red-600"},[_vm._v(" This field is required ")]):_vm._e()])],1)]),_c('div',{staticClass:"col-span-6 sm:col-span-6"},[_c('button',{class:[
                'inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60',
                _vm.addingToBag ? 'cursor-not-allowed hover:bg-gray-600' : 'hover:bg-gray-700',
            ],attrs:{"data-cy":"submit-button","type":"submit","disabled":_vm.shouldDisableSubmit},on:{"click":function($event){$event.preventDefault();return _vm.addToBag.apply(null, arguments)}}},[(_vm.addingToBag)?_c('svg',{key:"loading-indicator",staticClass:"-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700",attrs:{"data-cy":"loading-indicator","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","aria-hidden":"true"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_vm._e(),(_vm.addingToBag)?_c('span',{key:"processing",staticClass:"font-barlo font-medium uppercase tracking-wider"},[_vm._v("Please wait...")]):_c('span',{key:"text",staticClass:"font-barlo font-medium uppercase tracking-wider"},[_vm._v("ADD TO BAG")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }