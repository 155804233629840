export const namespaced = true;

export const state = {
    timerIsVisible: false,
};

export const mutations = {
    SET_TIMER_VISIBILITY(state, payload) {
        state.timerIsVisible = payload;
    },
};

export const actions = {
    setTimerVisibility({ commit }, payload) {
        commit('SET_TIMER_VISIBILITY', payload);
    },
};

export const getters = {
    timerIsVisible: (state) => {
        return state.timerIsVisible;
    },
};
