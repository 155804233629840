<template>
    <div class="mb-8 rounded-md bg-sky-200 p-4">
        <div class="flex justify-center">
            <transition
                enter-active-class="transition-opacity ease-linear duration-75"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-75"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
                mode="out-in"
            >
                <p v-if="duration > 0" class="text-sm font-medium text-sky-700">
                    Due to limited availability, we can hold this for you for
                    {{ formattedDuration }}
                </p>
                <p v-if="duration === 0" class="text-sm font-medium text-sky-700">
                    Oops! Your time has expired. You'll need to start over to reserve your spot.
                </p>
            </transition>
        </div>
    </div>
</template>

<script lang="js">
import {format} from "date-fns";

export default {
  name: 'CheckoutTimer',
  data() {
    return {
      format,
      duration: 0,
    }
  },
  computed: {
    location() {
      return this.$store.getters['location/location'];
    },
    formattedDuration() {
      const date = new Date(0);
      date.setSeconds(this.duration);

      return format(date, 'm:ss');
    }
  },
  created() {
    this.duration = this.location.checkoutTimer;

    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.duration > 0) {
          this.duration--;
        } else {
          clearInterval(this.timer);

          // Timer has reached zero.
        }
      }, 1000);
    }
  }
}
</script>
