<template>
    <div
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
        :class="['relative z-10', open ? 'fixed inset-0 overflow-hidden' : 'absolute -z-50']"
    >
        <!-- overlay -->
        <transition
            enter-active-class="transition-opacity ease-linear duration-75 sm:duration-100"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-75 sm:duration-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="open"
                class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
            ></div>
        </transition>
        <!-- /overlay -->

        <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <!-- slide over panel -->
                    <transition
                        enter-active-class="transform transition ease-linear duration-75 sm:duration-100"
                        enter-class="translate-x-full"
                        enter-to-class="translate-x-0"
                        leave-active-class="transform transition ease-linear duration-75 sm:duration-100"
                        leave-class="translate-x-0"
                        leave-to-class="translate-x-full"
                    >
                        <div v-if="open" class="pointer-events-auto w-screen max-w-2xl">
                            <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <header class="border-b bg-gray-50 px-4 py-4 sm:px-6">
                                    <div class="flex items-center justify-between">
                                        <!-- close button -->
                                        <button
                                            type="button"
                                            class="hover:text-gray:700 group relative flex flex-col items-center rounded-md text-gray-400 transition duration-75 ease-linear focus:outline-none focus:ring-0"
                                            @click="closePanel"
                                        >
                                            <span class="absolute -inset-2.5"></span>
                                            <span class="sr-only">Close panel</span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-4 w-4 text-gray-500 transition duration-100 ease-linear group-hover:text-gray-700"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                            <span
                                                class="relative -inset-0.5 pl-1 font-sans text-xs text-gray-500 underline transition duration-100 ease-linear group-hover:text-gray-700"
                                                >CLOSE</span
                                            >
                                        </button>
                                        <!-- /close button -->
                                        <div class="flex h-7 items-center">
                                            <WidgetTabs v-if="locationIsAvailable" />
                                        </div>
                                    </div>
                                </header>

                                <main
                                    :class="[
                                        'relative flex-1 overflow-y-auto bg-gray-50 px-4',
                                        locationIsLoading || somethingBadHappened
                                            ? 'flex items-center py-0 sm:py-0'
                                            : 'py-6 sm:px-6 sm:py-6',
                                    ]"
                                >
                                    <!-- loading indicator -->
                                    <transition
                                        enter-active-class="transition-opacity ease-linear duration-75"
                                        enter-class="opacity-0"
                                        enter-to-class="opacity-100"
                                        leave-active-class="transition-opacity ease-linear duration-75"
                                        leave-class="opacity-100"
                                        leave-to-class="opacity-0"
                                        mode="out-in"
                                    >
                                        <LoadingIndicator v-if="locationIsLoading" />
                                    </transition>
                                    <!-- /loading indicator -->

                                    <!-- network feedback -->
                                    <transition
                                        enter-active-class="transition-opacity ease-linear duration-75"
                                        enter-class="opacity-0"
                                        enter-to-class="opacity-100"
                                        leave-active-class="transition-opacity ease-linear duration-75"
                                        leave-class="opacity-100"
                                        leave-to-class="opacity-0"
                                        mode="out-in"
                                    >
                                        <NetworkFeedback v-if="networkError" />
                                    </transition>
                                    <!-- /network feedback -->

                                    <!-- checkout timer -->
                                    <transition
                                        enter-active-class="transition-opacity ease-linear duration-100"
                                        enter-class="opacity-0"
                                        enter-to-class="opacity-100"
                                        leave-active-class="transition-opacity ease-linear duration-100"
                                        leave-class="opacity-100"
                                        leave-to-class="opacity-0"
                                        mode="out-in"
                                    >
                                        <CheckoutTimer v-if="timerIsVisible" />
                                    </transition>
                                    <!-- /checkout timer -->

                                    <!-- location capabilities -->
                                    <transition-group
                                        enter-active-class="transition delay-300 ease-out duration-75"
                                        enter-class="opacity-0"
                                        enter-to-class="opacity-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-class="opacity-100"
                                        leave-to-class="opacity-0"
                                    >
                                        <ReservationsComponent
                                            v-if="
                                                locationIsAvailable && activeTab === 'RESERVATIONS'
                                            "
                                            key="reservations"
                                        />

                                        <EventsComponent
                                            v-if="locationIsAvailable && activeTab === 'EVENTS'"
                                            key="events"
                                        />

                                        <CartComponent
                                            v-if="locationIsAvailable && activeTab === 'CART'"
                                            key="cart"
                                        />

                                        <OrderCompleted
                                            v-if="currentStep === 'COMPLETED'"
                                            key="completed"
                                        />
                                    </transition-group>
                                    <!-- /location capabilities -->

                                    <!-- continue or checkout -->
                                    <ContinueOrCheckout v-if="currentStep === 'CONTINUING'" />
                                    <!-- /continue or checkout -->
                                </main>

                                <!-- checkout button -->
                                <transition
                                    enter-active-class="transform transition duration-75 ease-linear delay-300"
                                    enter-class="translate-y-full"
                                    enter-to-class="translate-y-0"
                                    leave-active-class="transform transition duration-75 ease-linear"
                                    leave-class="translate-x-0"
                                    leave-to-class="translate-y-full"
                                >
                                    <div
                                        v-if="shouldShowCheckoutButton"
                                        class="flex flex-shrink-0 justify-center px-0 py-0"
                                    >
                                        <button
                                            type="submit"
                                            class="bottom-0 inline-flex w-full justify-center border border-transparent bg-gray-500 px-8 py-6 font-barlo text-xl font-medium uppercase tracking-wider text-white shadow-sm transition duration-75 ease-linear hover:bg-gray-600 focus:outline-none focus:ring-0"
                                            @click="checkout"
                                        >
                                            CHECKOUT
                                        </button>
                                    </div>
                                </transition>
                                <!-- /checkout button -->
                            </div>
                        </div>
                    </transition>
                    <!-- /slide over panel -->
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import ReservationsComponent from '@/components/reservations/ReservationsComponent.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import NetworkFeedback from '@/components/shared/NetworkFeedback.vue';
import CartComponent from '@/components/cart/CartComponent.vue';
import WidgetTabs from '@/components/WidgetTabs.vue';
import ContinueOrCheckout from "@/components/shared/ContinueOrCheckout.vue";
import OrderCompleted from "@/components/shared/OrderCompleted.vue";
import CheckoutTimer from "@/components/shared/CheckoutTimer.vue";
import EventsComponent from "@/components/events/EventsComponent.vue";

export default {
  name: 'WidgetSlideOver',
  components: {
    EventsComponent,
    CheckoutTimer,
    OrderCompleted,
    ContinueOrCheckout, CartComponent, ReservationsComponent, NetworkFeedback, LoadingIndicator, WidgetTabs
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: () => false,
    }
  },
  computed: {
    location() {
      return this.$store.getters['location/location'];
    },
    locationIsAvailable() {
      return !this.locationIsLoading && this.location;
    },
    locationIsLoading() {
      return this.$store.getters['location/locationIsLoading'];
    },
    networkError() {
      return !this.locationIsLoading && this.$store.getters['application/networkError'];
    },
    somethingBadHappened() {
      return !this.locationIsLoading && !this.location || !this.locationIsLoading && this.networkError;
    },
    activeTab() {
      return this.$store.getters['tab/activeTab'];
    },
    cart() {
      return this.$store.getters['cart/cart'];
    },
    itemsInCart() {
      return this.$store.getters['cart/items'];
    },
    cartIsEmpty() {
      return this.itemsInCart.length === 0;
    },
    currentStep() {
      return this.$store.getters['progress/currentStep'];
    },
    timerIsVisible() {
      return this.$store.getters['timer/timerIsVisible'];
    },
    shouldShowCheckoutButton() {
      return (
          !this.cartIsEmpty &&
          this.activeTab !== 'CART' &&
          this.currentStep !== 'CONFIRMING' &&
          this.currentStep !== 'CONTINUING' &&
          this.currentStep !== 'COMPLETED'
      );
    }
  },
  methods: {
    async checkout() {
      await this.$store.dispatch('tab/setActiveTab', 'CART');
      await this.$store.dispatch('timer/setTimerVisibility', true);
    },
    async closePanel() {
      this.$emit('close');
    },
  }
}
</script>
