<template>
    <div>
        <h3
            class="text-center font-barlo text-2xl font-medium uppercase tracking-wide text-gray-800"
        >
            Upcoming Events
        </h3>
        <div
            class="mb-4 flex justify-center font-barlo font-normal uppercase tracking-wider text-gray-400"
        >
            {{ location.name }}
        </div>
        <p class="text-center text-sm text-gray-500 md:mx-10">
            Curabitur ut lectus ullamcorper, feugiat sem in, tincidunt mauris.
        </p>

        <!-- loading -->
        <transition
            enter-active-class="transition delay-200 ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <LoadingIndicator v-if="eventsAreLoading" :message="'Getting events...'" />
        </transition>
        <!-- /loading -->

        <!-- events list -->
        <transition
            enter-active-class="transition delay-200 ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
        >
            <EventList
                v-if="!eventsAreLoading && events.length > 0"
                :events="events"
                class="mt-5"
                @event="handleSelectedEvent"
            />
        </transition>
        <!-- /events list -->

        <!-- event details -->
        <transition
            enter-active-class="transition-opacity ease-in-out duration-75"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-in-out duration-75"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
        >
            <div v-if="event" class="my-2 flex flex-col gap-8 lg:flex-row">
                <!-- left column -->
                <div class="flex w-full flex-col lg:w-2/3">
                    <div class="mb-5 flex flex-col">
                        <div class="flex flex-row space-x-4">
                            <!-- date -->
                            <div class="flex flex-row">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="mr-2 h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    />
                                </svg>
                                <span class="text-sm leading-6 text-gray-500">
                                    Mon Apr 15 2024
                                </span>
                            </div>
                            <!-- /date -->

                            <!-- time -->
                            <div class="flex flex-row">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="mr-2 h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                                <span class="text-sm leading-6 text-gray-500"> 7:00PM </span>
                            </div>
                            <!-- /time -->

                            <!-- cost -->
                            <div class="flex flex-row">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="mr-2 h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                    />
                                </svg>
                                <span class="text-sm leading-6 text-gray-500"
                                    >{{ event.ticketPrice }}
                                </span>
                            </div>
                            <!-- /cost -->
                        </div>
                    </div>
                    <h2
                        class="font-barlo text-2xl font-medium uppercase leading-6 tracking-wide text-gray-800"
                    >
                        {{ event.title }}
                    </h2>
                    <h3 class="leading-6 text-gray-800">{{ event.subtitle }}</h3>
                    <p class="mt-4 text-sm leading-6 text-gray-500">
                        {{ event.description }}
                    </p>
                </div>
                <!-- /left column -->

                <!-- right column -->
                <div class="flex w-full flex-col lg:w-1/3">
                    <EventForm />
                </div>
                <!-- /right column -->
            </div>
            <!-- /event details -->
        </transition>
    </div>
</template>

<script lang="js">
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EventList from '@/components/events/EventList.vue';
import EventForm from "@/components/events/EventForm.vue";

export default {
  name: 'EventsComponent',
  components: {EventForm, EventList, LoadingIndicator},
  computed: {
    location() {
      return this.$store.getters['location/location'];
    },
    eventsAreLoading() {
      return this.$store.getters['event/eventsAreLoading'];
    },
    eventIsLoading() {
      return this.$store.getters['event/eventIsLoading'];
    },
    event() {
      return this.$store.getters['event/event'];
    },
    events() {
      return this.$store.getters['event/events'];
    }
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents() {
      await this.$store.dispatch('event/fetchEvents', {
        pageSize: 100,
      });
    },
    async handleSelectedEvent(event) {
      await this.$store.dispatch('event/setEvent', event);
    }
  }
}
</script>
