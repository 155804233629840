export const namespaced = true;

export const state = {
    status: 'closed',
};

export const mutations = {
    SET_STATUS: function (state, payload) {
        state.status = payload;
    },
};

export const actions = {
    async setStatus({ commit }, payload) {
        commit('SET_STATUS', payload);
    },
};

export const getters = {
    status: (state) => {
        return state.status;
    },
};
