<template>
    <div class="flex flex-col">
        <transition
            enter-active-class="transition-opacity ease-linear duration-75"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-75"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
        >
            <EmptyCartComponent v-if="shouldShowEmptyCartComponent" />
        </transition>

        <!-- items in bag -->
        <transition
            enter-active-class="transition-opacity ease-linear duration-75"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-75"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
        >
            <div v-if="!cartIsEmpty">
                <div class="flex flex-col items-center justify-center">
                    <h3
                        class="mb-4 text-center font-barlo text-2xl font-medium uppercase tracking-wide text-gray-800"
                    >
                        {{
                            itemsInCart.length > 1
                                ? `${itemsInCart.length} items in your order`
                                : `1 item in your order`
                        }}
                    </h3>
                </div>
            </div>
        </transition>
        <!-- /items in bag -->

        <div v-if="!cartIsEmpty" class="mt-8 flex flex-col lg:flex-row">
            <!-- left column -->
            <div class="flex w-full flex-col lg:w-1/2">
                <h3
                    class="mb-4 font-barlo text-lg font-medium uppercase leading-6 tracking-wider text-gray-800"
                >
                    Your Information
                </h3>

                <OrderForm />
            </div>
            <!-- /left column -->

            <!-- right column -->
            <div class="flex w-full flex-col pt-10 lg:w-1/2 lg:pt-0">
                <div v-if="location && !cartIsEmpty" class="flex flex-col px-0 lg:pl-10">
                    <div v-if="location" class="flex flex-col px-0 lg:pr-10">
                        <div class="flex flex-row align-middle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="mr-3 h-6 w-6 text-gray-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                            <div class="flex flex-col">
                                <h3
                                    class="font-barlo text-lg font-medium uppercase leading-6 tracking-wider text-gray-800"
                                >
                                    {{ location.name }}
                                </h3>
                                <p class="font-sans text-xs text-gray-800">
                                    {{ location.streetAddress1 }}
                                    {{ location.streetAddress2 }}
                                    <br />
                                    {{ location.city }}, {{ location.state }}
                                    {{ location.zip }}
                                </p>
                                <h5 class="mt-3">
                                    <a
                                        href="#"
                                        class="text-md text-tango-500 hover:text-tango-400 font-barlo font-medium uppercase leading-6 tracking-wider transition duration-200 ease-in-out"
                                    >
                                        Get directions
                                    </a>
                                </h5>
                            </div>
                        </div>

                        <div class="mt-4 flex flex-row align-middle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="mr-3 h-6 w-6 text-gray-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                />
                            </svg>
                            <span
                                class="font-sans font-medium leading-6 transition duration-200 ease-in-out"
                            >
                                <a :href="`tel:${location.phoneNumber}`">
                                    {{ location.phoneNumber | friendlyPhoneNumber }}
                                </a>
                            </span>
                        </div>
                    </div>

                    <!-- item list -->
                    <transition
                        mode="out-in"
                        enter-active-class="transition ease-out duration-300"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition ease-in duration-100"
                        leave-class="opacity-100"
                        w
                        leave-to-class="opacity-0"
                    >
                        <div v-if="!cartIsEmpty" class="my-7 flex w-full justify-center">
                            <div class="flex w-full overflow-hidden bg-white shadow sm:rounded-md">
                                <CartList :items="itemsInCart" />
                            </div>
                        </div>
                    </transition>
                    <!-- /item list -->

                    <hr class="text-gray-500" />
                    <p
                        class="mt-2 text-right font-barlo text-lg font-medium uppercase leading-6 tracking-wide text-gray-800"
                    >
                        Subtotal: $0.00
                    </p>
                </div>
            </div>
            <!-- /right column -->
        </div>

        <!-- cancellation policy -->
        <div v-if="!cartIsEmpty && location.cancellationPolicy" class="my-8">
            <p class="font-sans text-xs font-bold uppercase text-gray-600">Cancellation Policy</p>
            <p class="font-sans text-xs text-gray-400" v-html="location.cancellationPolicy" />
        </div>
        <!-- /cancellation policy -->
    </div>
</template>

<script lang="js">
import EmptyCartComponent from '@/components/cart/EmptyCartComponent.vue';
import OrderForm from '@/components/cart/OrderForm.vue';
import CartList from '@/components/cart/CartList.vue';

export default {
  name: 'CartComponent',
  components: {CartList, OrderForm, EmptyCartComponent},
  filters: {
    friendlyPhoneNumber(value) {
      if (!value) return '';

      let cleaned = ('' + value).replace(/\D/g, '');

      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    },
  },
  computed: {
    location() {
      return this.$store.getters['location/location'];
    },
    order() {
      return this.$store.getters['order/order'];
    },
    cart() {
      return this.$store.getters['cart/cart'];
    },
    itemsInCart() {
      return this.$store.getters['cart/items'];
    },
    cartIsEmpty() {
      return this.itemsInCart.length === 0;
    },
    shouldShowEmptyCartComponent() {
      return this.cartIsEmpty && this.currentStep !== 'COMPLETED';
    },
    currentStep() {
      return this.$store.getters["progress/currentStep"];
    },
  }
}
</script>
