var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-2 mb-8 w-full cursor-pointer last:mb-0 sm:mb-6"},[_c('div',{staticClass:"flex-start flex items-center"},[_c('button',{class:[
                'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 font-barlo text-base font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60',
                _vm.opening.confirming
                    ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 disabled:bg-gray-300 disabled:text-gray-700'
                    : 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 disabled:bg-gray-300 disabled:text-gray-700',
            ],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.handleSelectedOpening(_vm.opening)}}},[[_c('div',{staticClass:"flex flex-row items-center"},[_vm._l((_vm.opening.activities),function(activity,index){return _c('div',{key:index},[(activity.name === 'Axe Throwing')?_c('svg',{staticClass:"mr-3 h-5 w-5 transition duration-200 ease-in-out",attrs:{"viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"id":"Page-1","stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('g',{staticClass:"stroke-current",class:[_vm.opening.confirming ? 'text-white' : 'text-white'],attrs:{"transform":"translate(-685.000000, -510.000000)","stroke-width":"1.40350877"}},[_c('g',{attrs:{"id":"white","transform":"translate(685.000000, 511.000000)"}},[_c('rect',{attrs:{"transform":"translate(17.317224, 5.741784) rotate(-315.000000) translate(-17.317224, -5.741784)","x":"16.3815519","y":"4.30903579","width":"1.87134503","height":"2.86549708","rx":"0.935672515"}}),_c('rect',{attrs:{"transform":"translate(6.338461, 16.720548) rotate(-315.000000) translate(-6.338461, -16.720548) ","x":"5.40278871","y":"10.1708399","width":"1.87134503","height":"13.0994152","rx":"0.935672515"}}),_c('path',{attrs:{"d":"M18.2865183,9.00321368 L13.204824,14.1702216 C11.2180702,12.1912683 9.71244826,10.94818 8.6879583,10.4409566 C7.66346834,9.93373317 6.13200465,9.65857438 4.09356725,9.61548018 C4.25677978,6.51019882 5.34098307,4.11321512 7.34617713,2.4245291 C9.35137119,0.735843081 11.4725939,-0.0707147956 13.7098453,0.00485547265 C13.7098453,2.25010508 14.0338932,3.90100597 14.681989,4.95755813 C15.3300848,6.0141103 16.5315946,7.36266215 18.2865183,9.00321368 Z","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M13.7171909,3.03491547 C11.1900428,3.59155581 8.3576748,6.00385832 7.41361562,9.65483714"}})])])])]):_vm._e()])}),_c('div',{class:[
                            'font-sans text-sm font-bold tracking-wide text-white',
                            _vm.opening.confirming ? '' : '',
                        ]},[_c('div',[_vm._v(_vm._s(_vm._f("friendlyTime")(_vm.opening.dateTimeStart,_vm.location)))]),(
                                _vm.location.durations[0].reservations.minimumDuration !==
                                _vm.location.durations[0].reservations.maximumDuration
                            )?_c('div',{staticClass:"flex items-center font-sans text-xs font-normal tracking-wide text-white"},[_vm._v(" "+_vm._s(_vm.friendlyDuration)+" ")]):_vm._e()])],2)]],2),(_vm.opening.confirming)?_c('button',{class:[
                'ml-2 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 font-barlo text-base font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60',
                _vm.opening.confirming
                    ? 'bg-gray-400 hover:bg-gray-600 focus:ring-gray-400 disabled:bg-gray-200 disabled:text-gray-600'
                    : 'bg-gray-500 hover:bg-gray-700 focus:ring-gray-500 disabled:bg-gray-300 disabled:text-gray-700',
            ],attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmSelectedOpening(_vm.opening)}}},[_c('span',{staticClass:"font-sans text-sm font-bold tracking-wider"},[_vm._v("CONFIRM")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }