<template>
    <ul class="w-full divide-y divide-gray-200">
        <CartListItem v-for="(item, idx) in items" :key="`item-${idx}`" :item="item" />
    </ul>
</template>

<script>
import CartListItem from '@/components/cart/CartListItem.vue';

export default {
    name: 'CartList',
    components: { CartListItem },
    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
};
</script>
