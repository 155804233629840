<template>
    <form>
        <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <!-- given name -->
            <div class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        class="relative rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1"
                        :class="[
                            'relative rounded-md border px-3 py-2 shadow-sm focus-within:ring-1',
                            $v.order.givenName.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="givenName"
                            class="block text-xs font-medium"
                            :class="[
                                'block text-xs font-medium',
                                $v.order.givenName.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                        >
                            First name
                        </label>
                        <input
                            id="givenName"
                            v-model.trim="$v.order.givenName.$model"
                            type="text"
                            name="givenName"
                            autocomplete="given-name"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.order.givenName.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            placeholder="Tony"
                            required
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.order.givenName.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="$v.order.givenName.$error && !$v.order.givenName.$error.required"
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /given name -->

            <!-- family name -->
            <div class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        class="relative rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1"
                        :class="[
                            'relative rounded-md border px-3 py-2 shadow-sm focus-within:ring-1',
                            $v.order.familyName.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="familyName"
                            class="block text-xs font-medium"
                            :class="[
                                'block text-xs font-medium',
                                $v.order.familyName.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                        >
                            Last name
                        </label>
                        <input
                            id="familyName"
                            v-model.trim="$v.order.familyName.$model"
                            type="text"
                            name="familyName"
                            autocomplete="family-name"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.order.familyName.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            placeholder="Stark"
                            required
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.order.familyName.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="
                                $v.order.familyName.$error && !$v.order.familyName.$error.required
                            "
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /family name -->

            <!-- email -->
            <div class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        class="relative rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1"
                        :class="[
                            'relative rounded-md border px-3 py-2 shadow-sm focus-within:ring-1',
                            $v.order.email.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="email"
                            class="block text-xs font-medium"
                            :class="[
                                'block text-xs font-medium',
                                $v.order.email.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                        >
                            Email address
                        </label>
                        <input
                            id="email"
                            v-model.trim="$v.order.email.$model"
                            type="email"
                            name="email"
                            autocomplete="email"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.order.email.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            placeholder="example@email.com"
                            required
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.order.givenName.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition-group
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="$v.order.email.$error && !$v.order.email.$error.required"
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                        <p
                            v-if="$v.order.email.$error && !$v.order.email.email"
                            key="email"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            Please use a valid email address
                        </p>
                    </transition-group>
                </div>
            </div>
            <!-- /email -->

            <!-- phone -->
            <div class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        class="relative rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1"
                        :class="[
                            'relative rounded-md border px-3 py-2 shadow-sm focus-within:ring-1',
                            $v.order.phoneNumber.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="phoneNumber"
                            class="block text-xs font-medium"
                            :class="[
                                'block text-xs font-medium',
                                $v.order.phoneNumber.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                        >
                            Phone number
                        </label>
                        <input
                            id="phoneNumber"
                            v-model.trim="$v.order.phoneNumber.$model"
                            v-mask="'+1 (###) ###-####'"
                            type="tel"
                            name="phoneNumber"
                            autocomplete="tel"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.order.phoneNumber.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            placeholder="(123)-827-5308"
                            required
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.order.phoneNumber.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="
                                $v.order.phoneNumber.$error && !$v.order.phoneNumber.$error.required
                            "
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /phone -->

            <!-- comments -->
            <div class="col-span-6 sm:col-span-6">
                <div>
                    <div
                        :class="[
                            'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                            $v.order.comments.$error
                                ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                        ]"
                    >
                        <label
                            for="comments"
                            :class="[
                                'block text-xs font-medium',
                                $v.order.comments.$error ? 'text-red-700' : 'text-gray-900',
                            ]"
                            >Comments</label
                        >
                        <textarea
                            id="comments"
                            v-model.trim="$v.order.comments.$model"
                            rows="6"
                            name="comments"
                            :class="[
                                'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                $v.order.comments.$error
                                    ? 'text-red-900 placeholder-red-300'
                                    : 'text-gray-900 placeholder-gray-300',
                            ]"
                            placeholder="Any comments or special requests?"
                        />
                        <transition
                            enter-active-class="transition-opacity ease-linear duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-linear duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="$v.order.comments.$error"
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <svg
                                    class="h-5 w-5 text-red-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </transition>
                    </div>
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <p
                            v-if="$v.order.comments.$error && !$v.order.comments.$error.required"
                            key="required"
                            class="mt-2 text-sm font-medium text-red-600"
                        >
                            This field is required
                        </p>
                    </transition>
                </div>
            </div>
            <!-- /comments -->

            <!-- opt in -->
            <div v-if="shouldShowOptIn" class="col-span-6 sm:col-span-6">
                <div class="relative flex items-start">
                    <div class="flex h-5 items-center">
                        <input
                            id="opt-in"
                            v-model="hasOptedIn"
                            name="opt-in"
                            value="yes"
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-gray-600 transition duration-75 ease-linear focus:ring-gray-500"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="opt-in" class="cursor-pointer font-medium text-gray-700"
                            >Yes, I'd like to receive occasional updates!
                        </label>
                        <p class="mt-1 text-xs text-gray-500">We don't share your information.</p>
                    </div>
                </div>
            </div>
            <!-- /opt in -->
        </div>

        <div class="col-span-6 mt-8 flex justify-between sm:col-span-6 sm:mb-8">
            <button
                type="button"
                class="rounded-md border border-gray-300 bg-white px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-gray-700 shadow-sm transition duration-75 ease-linear hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                @click="cancel"
            >
                Cancel
            </button>
            <button
                data-cy="submit-button"
                type="submit"
                :disabled="shouldDisableSubmit"
                :class="[
                    'ml-4 inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60 md:ml-4',
                    orderIsLoading ? 'cursor-not-allowed hover:bg-gray-600' : 'hover:bg-gray-700',
                ]"
                @click.prevent="confirmOrder"
            >
                <svg
                    v-if="orderIsLoading"
                    key="loading-indicator"
                    data-cy="loading-indicator"
                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                <span
                    v-if="orderIsLoading"
                    key="processing"
                    class="font-barlo font-medium uppercase tracking-wider"
                    >Please wait...</span
                >
                <span v-else key="text" class="font-barlo font-medium uppercase tracking-wider"
                    >Confirm Order</span
                >
            </button>
        </div>
    </form>
</template>
<script lang="js">
import {email, required} from 'vuelidate/lib/validators';

export default {
  name: 'OrderForm',
  data() {
    return {
      hasOptedIn: true,
    }
  },
  computed: {
    ccAccessToken() {
      return this.$store.getters['constantContact/accessToken'];
    },
    shouldDisableSubmit() {
      return this.orderIsLoading || this.$v.$invalid;
    },
    orderIsLoading() {
      return this.$store.getters['order/orderIsLoading'];
    },
    order() {
      return this.$store.getters['order/order'];
    },
    location() {
      return this.$store.getters['location/location'];
    },
    shouldShowOptIn() {
      return this.location.customFields[0].optIn.isEnabled;
    }
  },
  methods: {
    async processCartItems(items) {
      for (const item of items) {
        if (item.type === 'RESERVATION') {
          const reservation = await this.$store.dispatch('reservation/fetchReservation', {
            reservationId: item.id,
          });

          reservation.status = 'CONFIRMED';
          reservation.organizationId = reservation.order.organizationId;
          reservation.locationId = reservation.order.locationId;
          reservation.reservableId = reservation.reservable.id;
          reservation.orderId = reservation.order.id;

          const reservationPayload = {
            reservation,
            method: 'PATCH',
          };

          await this.$store.dispatch('reservation/saveReservation', reservationPayload);
        }
      }
    },
    async confirmOrder() {
      await this.$store.dispatch('order/setOrderLoadingStatus', true);

      this.$v.order.$touch();

      if (!this.$v.$invalid) {
        await this.processCartItems(this.order.purchasables);

        try {
          const payload = {
            order: {
              id: this.order.id,
              organizationId: this.order.organizationId,
              locationId: this.location.id,
              givenName: this.$v.order.givenName.$model,
              familyName: this.$v.order.familyName.$model,
              email: this.$v.order.email.$model,
              phoneNumber: this.$v.order.phoneNumber.$model,
              purchasables: this.order.purchasables,
              comments: this.$v.order.comments.$model,
              createdBy: this.order.createdBy,
              optIn: this.hasOptedIn,
              status: 'COMPLETED',
              ccAccessToken: this.ccAccessToken,
            },
            method: 'PATCH',
          };

          console.log('ORDER PAYLOAD:', payload);

          await this.$store.dispatch('order/saveOrder', payload);
          await this.$store.dispatch(
              'progress/setCurrentStep',
              'COMPLETED'
          );

          await this.$store.dispatch('opening/reset');
          await this.$store.dispatch('search/setPartySize', '');
          await this.$store.dispatch('cart/setItems', []);
          await this.$store.dispatch('order/setOrder', undefined);
          await this.$store.dispatch('timer/setTimerVisibility', false);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    },
    async cancel() {
      await this.$store.dispatch('progress/setCurrentStep', 'BROWSING');
      await this.$store.dispatch('opening/setOpening', undefined);
      await this.$store.dispatch('search/setPartySize', '');
      await this.$store.dispatch('tab/setActiveTab', 'RESERVATIONS');
    }
    ,
  },
  validations: {
    order: {
      givenName: {required},
      familyName: {required},
      phoneNumber: {required},
      email: {required, email},
      comments: {},
      createdBy: {},
    },
  },
}
</script>
