<template>
    <div>
        <!-- mobile navigation -->
        <div class="sm:hidden">
            <label for="mobile-tabs" class="sr-only">Chose an option</label>
            <select
                id="mobile-tabs"
                class="block w-full rounded-md border-gray-300 transition duration-75 ease-linear focus:border-gray-500 focus:ring-gray-500"
                @change="changeActiveTab($event)"
            >
                <option
                    v-for="(option, index) in tabOptions"
                    :key="`option-${index}`"
                    :value="option.value"
                    :selected="option.value === activeTab"
                >
                    {{ option.text }}
                </option>
            </select>
        </div>
        <!-- /mobile navigation -->

        <!-- desktop tabs -->
        <div class="hidden sm:block">
            <nav class="flex space-x-4" aria-label="Tabs">
                <a
                    v-for="(option, index) in tabOptions"
                    :key="`tab-${index}`"
                    href="#"
                    :class="[
                        'group relative inline-flex items-center border-b-2 border-transparent px-1 py-1 font-barlo text-sm font-medium uppercase tracking-wider transition duration-75 ease-linear',
                        activeTab === option.value
                            ? 'border-gray-500 text-gray-700'
                            : 'border-transparent text-gray-600 hover:border-gray-500 hover:text-gray-800',
                    ]"
                    @click.prevent="setActiveTab(option.value)"
                >
                    <!-- reservations -->
                    <svg
                        v-if="option.value === 'RESERVATIONS'"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="[
                            '-ml-0.5 h-6 w-6 transition duration-75 ease-linear',
                            activeTab === 'RESERVATIONS'
                                ? 'text-gray-700'
                                : 'text-gray-400 group-hover:text-gray-700',
                        ]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <!-- /reservations -->

                    <!-- events -->
                    <svg
                        v-if="option.value === 'EVENTS'"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="[
                            '-ml-0.5 h-6 w-6 transition duration-75 ease-linear',
                            activeTab === 'EVENTS'
                                ? 'text-gray-700'
                                : 'text-gray-400 group-hover:text-gray-700',
                        ]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                    </svg>
                    <!-- /events -->

                    <!-- leagues -->
                    <svg
                        v-if="option.value === 'LEAGUES'"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="[
                            '-ml-0.5 h-6 w-6 transition duration-75 ease-linear',
                            activeTab === 'LEAGUES'
                                ? 'text-gray-700'
                                : 'text-gray-400 group-hover:text-gray-700',
                        ]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                    </svg>
                    <!-- /leagues -->

                    <ItemsIndicator v-if="option.value === 'CART'" />

                    <!-- cart -->
                    <svg
                        v-if="option.value === 'CART'"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="[
                            '-ml-0.5 h-6 w-6 transition duration-75 ease-linear',
                            activeTab === 'CART'
                                ? 'text-gray-700'
                                : 'text-gray-400 group-hover:text-gray-700',
                        ]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                    </svg>
                    <!-- /cart -->
                </a>
            </nav>
        </div>
        <!-- /desktop tabs -->
    </div>
</template>

<script lang="js">
import ItemsIndicator from '@/components/shared/ItemsIndicator.vue';
import dayjs from "dayjs";

export default {
  name: 'WidgetTabs',
  components: {ItemsIndicator},
  data() {
    return {
      tabOptions: [
        {text: 'Reservations', value: 'RESERVATIONS'},
        {text: 'Events', value: 'EVENTS'},
        {text: 'Leagues', value: 'LEAGUES'},
        {text: 'Cart', value: 'CART'},
      ],
    };
  },
  computed: {
    activeTab() {
      return this.$store.getters['tab/activeTab'];
    },
    cart() {
      return this.$store.getters['cart/cart'];
    },
    cartIsEmpty() {
      return true;
    },
    location() {
      return this.$store.getters['location/location'];
    }
  },
  methods: {
    async setActiveTab(tab) {
      await this.$store.dispatch('tab/setActiveTab', tab);
      await this.$store.dispatch('progress/setCurrentStep', 'BROWSING');
      await this.$store.dispatch('opening/setOpening', undefined);
      await this.$store.dispatch('search/setPartySize', '');
      await this.$store.dispatch('event/setEvent', undefined);

      if (tab === 'RESERVATIONS') {
        await this.$store.dispatch('opening/reset');

        await this.searchOpenings();
      }
    },
    async searchOpenings() {
      const beginning = dayjs(new Date());

      try {
        await this.$store.dispatch('opening/fetchOpenings', {
          locationId: this.location.id,
          beginning: beginning.format('YYYY-MM-DD'),
          activityId: (this.selectedActivityId) ? this.selectedActivityId : '',
          pageSize: 100,
          partySize: (this.partySize) ? this.partySize : '',
        });
      } catch (err) {
        console.log('error searching availability', err);
      }
    },
    async changeActiveTab(event) {
      await this.setActiveTab(event.target.value);
    },
  },
}
</script>
