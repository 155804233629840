export const namespaced = true;

import Jsona from 'jsona';

import OpeningService from '@/services/OpeningService';

const dataFormatter = new Jsona();

export const state = {
    openings: [],
    opening: undefined,
    searching: false,
    openingsAreLoading: false,
    exceptionMessage: '',
};

export const mutations = {
    SET_OPENINGS(state, payload) {
        state.openings = payload;
    },
    SET_OPENING(state, payload) {
        state.opening = payload;
    },
    SET_SEARCH_LOADING_STATUS(state, payload) {
        state.searching = payload;
    },
    SET_OPENINGS_LOADING_STATUS(state, payload) {
        state.openingsAreLoading = payload;
    },
    SET_EXCEPTION_MESSAGE(state, payload) {
        state.exceptionMessage = payload;
    },
};

export const actions = {
    async fetchOpenings({ commit }, payload) {
        console.log('fetchOpenings called:', payload);

        commit('SET_OPENINGS_LOADING_STATUS', true);
        commit('SET_OPENINGS', []);

        const response = await OpeningService.fetchOpenings(payload);
        let openings = dataFormatter.deserialize(response.data);

        if (openings === undefined) {
            console.log('openings are undefined');
            openings = [];
            commit('SET_EXCEPTION_MESSAGE', response.data.meta.message);
        }

        commit('SET_OPENINGS', openings);
        commit('SET_OPENINGS_LOADING_STATUS', false);
    },
    setOpenings({ commit }, payload) {
        commit('SET_OPENINGS', payload);
    },
    setExceptionMessage({ commit }, payload) {
        commit('SET_EXCEPTION_MESSAGE', payload);
    },
    setOpening({ commit }, payload) {
        const openings = state.openings.map((opening) => ({
            ...opening,
            confirming: opening === payload ? opening.confirming : false,
        }));

        commit('SET_OPENING', payload);
        commit('SET_OPENINGS', openings);
    },
    reset({ commit }) {
        commit('SET_SEARCH_LOADING_STATUS', false);
        commit('SET_OPENING', undefined);
        commit('SET_OPENINGS', []);
    },
};

export const getters = {
    openings: (state) => {
        return state.openings;
    },
    opening: (state) => {
        return state.opening;
    },
    searching: (state) => {
        return state.searching;
    },
    openingsAreLoading: (state) => {
        return state.openingsAreLoading;
    },
    exceptionMessage: (state) => {
        return state.exceptionMessage;
    },
};
