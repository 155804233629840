<template>
    <form class="flex h-full flex-col space-y-5">
        <!-- ticket qty -->
        <div class="col-span-6 sm:col-span-6">
            <div>
                <div
                    :class="[
                        'relative rounded-md border bg-white px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                        $v.ticket.qty.$error
                            ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                            : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                    ]"
                >
                    <label
                        for="qty"
                        :class="[
                            'block text-xs font-medium',
                            $v.ticket.qty.$error ? 'text-red-700' : 'text-gray-900',
                        ]"
                        >Quantity</label
                    >
                    <input
                        id="qty"
                        v-model.number="$v.ticket.qty.$model"
                        type="number"
                        name="qty"
                        step="1"
                        min="1"
                        autocomplete="off"
                        :class="[
                            'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                            $v.ticket.qty.$error
                                ? 'text-red-900 placeholder-red-300'
                                : 'text-gray-900 placeholder-gray-300',
                        ]"
                        placeholder="1"
                        required
                    />
                    <transition
                        enter-active-class="transition-opacity ease-linear duration-75"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-linear duration-75"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <div
                            v-if="$v.ticket.qty.$error"
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                            <svg
                                class="h-5 w-5 text-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                    </transition>
                </div>
                <transition
                    enter-active-class="transition-opacity ease-linear duration-75"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity ease-linear duration-75"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <p
                        v-if="$v.ticket.qty.$error && !$v.ticket.qty.$error.required"
                        key="required"
                        class="mt-2 text-sm font-medium text-red-600"
                    >
                        This field is required
                    </p>
                </transition>
            </div>
        </div>
        <!-- /ticket qty -->

        <div class="col-span-6 sm:col-span-6">
            <button
                data-cy="submit-button"
                type="submit"
                :disabled="shouldDisableSubmit"
                :class="[
                    'inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60',
                    addingToBag ? 'cursor-not-allowed hover:bg-gray-600' : 'hover:bg-gray-700',
                ]"
                @click.prevent="addToBag"
            >
                <svg
                    v-if="addingToBag"
                    key="loading-indicator"
                    data-cy="loading-indicator"
                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                <span
                    v-if="addingToBag"
                    key="processing"
                    class="font-barlo font-medium uppercase tracking-wider"
                    >Please wait...</span
                >
                <span v-else key="text" class="font-barlo font-medium uppercase tracking-wider"
                    >ADD TO BAG</span
                >
            </button>
        </div>
    </form>
</template>

<script lang="js">
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'EventForm',
  computed: {
    event() {
      return this.$store.getters['event/event'];
    },
    addingToBag() {
      return false;
    },
    shouldDisableSubmit() {
      return false;
    },
  },
  validations: {
    ticket: {
      qty: {required},
    },
  },
  methods: {
    async addToBag() {
      //...
    },

  }
}
</script>
