<template>
    <div
        class="relative z-0 w-auto flex-1 overflow-y-auto focus:outline-none xl:order-first xl:border-r xl:border-gray-200"
    >
        <div class="flex h-full flex-col items-center justify-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-12 w-12 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            <div class="flex flex-col items-center justify-center">
                <h3 class="space-x-0 font-barlo text-xl font-bold uppercase text-gray-800">
                    Something went wrong
                </h3>
                <div class="flex flex-col items-center text-center text-gray-800">
                    <p v-if="hintIsAvailable" class="mt-1 font-sans text-xs text-gray-500">
                        ({{ hintIsAvailable }})
                    </p>
                    <p class="mt-4 font-sans text-sm">
                        <span class="font-bold">Please reload the page & try again.</span><br />
                        If the problem continues, please give us a call.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
  name: 'NetworkFeedback',
  computed: {
    feedback() {
      return {
        message: 'oof',
        response: {
          status: 404,
        }
      }
      // return this.$store.getters['application/networkError'];
    },
    hintIsAvailable() {
      return this.generateHint();
    }
  },
  methods: {
    generateHint() {
      if (this.feedback.message) {
        switch (this.feedback.response.status) {
          case 404:
            return 'We couldn\'t find the location you were looking for'
          default: return  ''
        }
      }
    }
  }
}
</script>
