<template>
    <div>
        <h3
            class="text-center font-barlo text-2xl font-medium uppercase tracking-wide text-gray-800"
        >
            Make a Reservation
        </h3>
        <div
            class="mb-4 flex justify-center font-barlo font-normal uppercase tracking-wider text-gray-400"
        >
            {{ location.name }}
        </div>
        <p
            v-if="locationHasWelcomeMessage"
            class="text-center font-sans text-sm text-gray-500 md:mx-10"
            v-html="location.widgetWelcomeMessage"
        ></p>

        <!-- datepicker -->
        <div class="mx-10 my-4 md:mx-20">
            <label for="search-date" class="sr-only block text-sm font-medium text-gray-700"
                >Choose a date</label
            >
            <div class="relative mt-1 rounded-md shadow-sm">
                <v-date-picker
                    v-model="calendar.selectedDate"
                    :masks="masks"
                    :is-expanded="false"
                    title-position="left"
                    :min-date="calendar.today"
                    :attributes="calendar.attributes"
                >
                    <template #default="{ inputValue, togglePopover }">
                        <input
                            id="search-date"
                            type="text"
                            name="search-date"
                            readonly
                            class="block w-full rounded-md border-gray-300 py-3 pr-10 font-medium text-gray-700 placeholder-gray-500 transition duration-75 ease-linear focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                            placeholder="Choose a date"
                            :value="inputValue"
                            @click.stop="togglePopover"
                        />
                    </template>
                </v-date-picker>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <!-- /datepicker -->

        <!-- party size -->
        <div class="mx-10 my-4 md:mx-20">
            <label for="search-party-size" class="sr-only block text-sm font-medium text-gray-700"
                >Party size</label
            >
            <div class="relative mt-1 rounded-md shadow-sm">
                <input
                    id="search-party-size"
                    v-model.number="partySize"
                    type="number"
                    step="1"
                    min="1"
                    name="search-party-size"
                    class="block w-full rounded-md border-gray-300 py-3 pr-10 font-medium text-gray-700 placeholder-gray-400 transition duration-75 ease-linear focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                    placeholder="Party size"
                />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <!-- /party size -->

        <!-- activity -->
        <transition
            enter-active-class="transition-opacity ease-linear duration-75"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-75"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-if="locationHasMoreThanOneActivity" class="mx-10 my-4 md:mx-20">
                <div>
                    <label
                        for="search-activity"
                        class="sr-only block text-sm font-medium text-gray-700"
                        >Activity</label
                    >
                    <div class="relative mt-1 rounded-md shadow-sm">
                        <button
                            v-click-away="hide"
                            type="button"
                            class="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 sm:text-sm sm:leading-6"
                            aria-haspopup="listbox"
                            aria-expanded="true"
                            aria-labelledby="listbox-label"
                            @click="toggleShowActivityOptions"
                        >
                            <span class="flex items-center">
                                <span
                                    class=""
                                    :class="[
                                        'block truncate font-medium',
                                        activity ? 'text-gray-700' : 'text-gray-400',
                                    ]"
                                    >{{ selectedActivity }}</span
                                >
                            </span>
                            <span
                                class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M13 10V3L4 14h7v7l9-11h-7z"
                                    />
                                </svg>
                            </span>
                        </button>

                        <!-- drop down items -->
                        <transition
                            enter-active-class=""
                            enter-class=""
                            enter-to-class=""
                            leave-active-class="transition ease-in duration-100"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <ul
                                v-show="showActivityOptions"
                                class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                tabindex="-1"
                                role="listbox"
                                aria-labelledby="listbox-label"
                                aria-activedescendant="listbox-option-3"
                            >
                                <li
                                    v-for="(activity, idx) in activities"
                                    id="listbox-option-0"
                                    :key="idx"
                                    :class="[
                                        'group relative cursor-pointer select-none py-2 pl-3 pr-9 font-medium transition duration-75 ease-linear',
                                        selectedActivity === activity.id
                                            ? 'bg-white text-gray-700'
                                            : 'text-gray-900 hover:bg-gray-600 hover:text-white',
                                    ]"
                                    role="option"
                                    @click="handleSelectedActivity(activity)"
                                >
                                    <div class="group flex items-center">
                                        <!-- axe throwing -->
                                        <svg
                                            v-if="
                                                activity.id ===
                                                '9acd3bca-7668-4d30-a368-2c75fb164975'
                                            "
                                            class="h-5 w-5"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g
                                                id="Page-1"
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <g
                                                    transform="translate(-685.000000, -510.000000)"
                                                    :class="[
                                                        'stroke-current transition duration-75 ease-linear',
                                                        selectedActivity === activity.id
                                                            ? 'text-gray-400'
                                                            : 'text-gray-400 group-hover:text-white',
                                                    ]"
                                                    stroke-width="1.40350877"
                                                >
                                                    <g
                                                        id="white"
                                                        transform="translate(685.000000, 511.000000)"
                                                    >
                                                        <rect
                                                            transform="translate(17.317224, 5.741784) rotate(-315.000000) translate(-17.317224, -5.741784)"
                                                            x="16.3815519"
                                                            y="4.30903579"
                                                            width="1.87134503"
                                                            height="2.86549708"
                                                            rx="0.935672515"
                                                        ></rect>
                                                        <rect
                                                            transform="translate(6.338461, 16.720548) rotate(-315.000000) translate(-6.338461, -16.720548) "
                                                            x="5.40278871"
                                                            y="10.1708399"
                                                            width="1.87134503"
                                                            height="13.0994152"
                                                            rx="0.935672515"
                                                        ></rect>
                                                        <path
                                                            d="M18.2865183,9.00321368 L13.204824,14.1702216 C11.2180702,12.1912683 9.71244826,10.94818 8.6879583,10.4409566 C7.66346834,9.93373317 6.13200465,9.65857438 4.09356725,9.61548018 C4.25677978,6.51019882 5.34098307,4.11321512 7.34617713,2.4245291 C9.35137119,0.735843081 11.4725939,-0.0707147956 13.7098453,0.00485547265 C13.7098453,2.25010508 14.0338932,3.90100597 14.681989,4.95755813 C15.3300848,6.0141103 16.5315946,7.36266215 18.2865183,9.00321368 Z"
                                                            stroke-linejoin="round"
                                                        ></path>
                                                        <path
                                                            d="M13.7171909,3.03491547 C11.1900428,3.59155581 8.3576748,6.00385832 7.41361562,9.65483714"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <!-- /axe throwing -->

                                        <!-- pickleball -->
                                        <svg
                                            v-if="
                                                activity.id ===
                                                '9ae146fa-e7c2-4339-bfa0-2bba1dce6f0c'
                                            "
                                            xmlns="http://www.w3.org/2000/svg"
                                            :class="[
                                                'h-5 w-5 stroke-current transition duration-75 ease-linear',
                                                selectedActivity === activity.id
                                                    ? 'text-gray-400'
                                                    : 'text-gray-400 group-hover:text-white',
                                            ]"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                            />
                                        </svg>
                                        <!-- pickleball -->

                                        <!-- activity text -->
                                        <span
                                            class=""
                                            :class="[
                                                'ml-3 block truncate transition duration-75 ease-linear',
                                                selectedActivity === activity.id
                                                    ? 'font-semibold'
                                                    : 'font-normal text-gray-600 group-hover:text-white',
                                            ]"
                                        >
                                            {{ activity.name }}
                                        </span>
                                        <!-- /activity text -->
                                    </div>

                                    <!-- checkmark -->
                                    <span
                                        v-if="selectedActivity === activity.name"
                                        :class="[
                                            'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-600 transition duration-75 ease-linear',
                                            selectedActivity === activity.name
                                                ? 'text-gray-600'
                                                : 'text-white',
                                        ]"
                                    >
                                        <svg
                                            class="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                    <!-- /checkmark -->
                                </li>
                            </ul>
                        </transition>
                        <!-- /drop down items -->
                    </div>
                    <p class="mt-2 text-xs text-gray-500">
                        Filter results by choosing an activity&mdash;or leave this blank to see all
                        openings!
                    </p>
                </div>
            </div>
        </transition>
        <!-- /activity -->

        <!-- check availability -->
        <div class="mx-10 my-4 md:mx-20">
            <div class="flex items-stretch">
                <button
                    data-cy="submit-button"
                    type="submit"
                    :disabled="shouldDisableSubmit"
                    :class="[
                        'inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-barlo text-base font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60',
                        openingsAreLoading
                            ? 'cursor-not-allowed hover:bg-gray-600'
                            : 'hover:bg-gray-700',
                    ]"
                    @click.prevent="searchOpenings"
                >
                    <svg
                        v-if="openingsAreLoading"
                        key="loading-indicator"
                        data-cy="loading-indicator"
                        class="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <span v-if="openingsAreLoading" key="searching" class="uppercase tracking-wider"
                        >PLEASE WAIT...</span
                    >
                    <span v-else key="check" class="uppercase tracking-wider"
                        >CHECK AVAILABILITY</span
                    >
                </button>
            </div>
        </div>
        <!-- /check availability -->
    </div>
</template>
<script lang="js">
import dayjs from 'dayjs';

import {ClickAway} from '@/directives/click-away';

export default {
  name: 'ReservationSearchForm',
  directives: {
    'click-away': ClickAway,
  },
  data() {
    return {
      showActivityOptions: false,
      partySize: '',
      activity: '',
      masks: {
        input: 'WWW MMM D',
      },
      calendar: {
        today: new Date(),
        weekSelected: [],
        selectedDate: new Date(),
        selectedActivityId: '',
        attributes: [
          {
            key: 'today',
            // highlight: {
            //   color: "orange",
            //   fillMode: "solid",
            // },
            dates: new Date(),
          },
        ],
        disabled_dates: [],
      },
    }
  },
  computed: {
    activities() {
      return this.$store.getters['activity/activities'];
    },
    location() {
      return this.$store.getters['location/location'];
    },
    shouldDisableSubmit() {
      return this.openingsAreLoading;
    },
    openingsAreLoading() {
      return this.$store.getters['opening/openingsAreLoading'];
    },
    selectedActivity() {
      return (this.activity) ? this.activity : 'Activity';
    },
    locationHasWelcomeMessage() {
      return this.location.widgetWelcomeMessage !== '';
    },
    locationHasMoreThanOneActivity() {
      return this.activities.length > 1;
    }
  },
  methods: {
    async searchOpenings() {
      const beginning = dayjs(this.calendar.selectedDate);

      try {
        await this.$store.dispatch('opening/fetchOpenings', {
          locationId: this.location.id,
          beginning: beginning.format('YYYY-MM-DD'),
          activityId: (this.selectedActivityId) ? this.selectedActivityId : '',
          pageSize: 100,
          partySize: (this.partySize) ? this.partySize : '',
        });
      } catch (err) {
        console.log('error searching availability', err);
      }
    },
    handleSelectedActivity(activity) {
      this.showActivityOptions = false;
      this.activity = activity.name;
      this.selectedActivityId = activity.id;
    },
    toggleShowActivityOptions() {
      this.showActivityOptions = !this.showActivityOptions;
    },
    hide(event, mouseover) {
      if (!mouseover) {
        this.showActivityOptions = false;
      }
    },
  }
}
</script>
