export const namespaced = true;

export const state = {
    items: [],
    cartIsLoading: false,
};

export const mutations = {
    SET_CART_LOADING_STATUS(state, payload) {
        state.cartIsLoading = payload;
    },
    ADD_ITEM: function (state, payload) {
        state.items.push(payload);
    },
    REMOVE_ITEM(state, itemToRemove) {
        state.items = state.items.filter((item) => {
            return item !== itemToRemove;
        });
    },
    SET_ITEMS: function (state, payload) {
        state.items = payload;
    },
};

export const actions = {
    addItem({ commit }, payload) {
        commit('ADD_ITEM', payload);
    },
    removeItem({ commit, dispatch }, payload) {
        if (payload.type === 'reservations') {
            commit('REMOVE_ITEM', payload);
        }

        if (state.items.length === 0) {
            dispatch('progress/setCurrentStep', 'BROWSING', {
                root: true,
            });

            dispatch('timer/setTimerVisibility', false, {
                root: true,
            });

            dispatch('opening/setOpening', undefined, {
                root: true,
            });

            dispatch('search/setPartySize', '', {
                root: true,
            });

            dispatch('tab/setActiveTab', 'RESERVATIONS', {
                root: true,
            });

            dispatch('order/setOrder', undefined, {
                root: true,
            });
        }
    },
    setItems({ commit }, payload) {
        commit('SET_ITEMS', payload);
    },
};

export const getters = {
    cart: (state) => {
        return state.items;
    },
    cartIsLoading: (state) => {
        return state.cartIsLoading;
    },
    items: (state) => {
        return state.items;
    },
};
