export const namespaced = true;

export const state = {
    activities: [],
    activity: undefined,
};

export const mutations = {
    SET_ACTIVITIES(state, payload) {
        state.activities = payload;
    },
    SET_ACTIVITY(state, payload) {
        state.activity = payload;
    },
};

export const actions = {
    setActivities({ commit }, payload) {
        commit('SET_ACTIVITIES', payload);
    },
    setActivity({ commit }, payload) {
        commit('SET_ACTIVITY', payload);
    },
    reset({ commit }) {
        commit('SET_ACTIVITY', undefined);
        commit('SET_ACTIVITIES', []);
    },
};

export const getters = {
    activities: (state) => {
        return state.activities;
    },
    activity: (state) => {
        return state.activity;
    },
};
