export const namespaced = true;

export const state = {
    networkError: undefined,
};

export const mutations = {
    SET_NETWORK_ERROR(state, payload) {
        state.networkError = payload;
    },
};

export const actions = {
    async setNetworkError({ commit }, payload) {
        commit('SET_NETWORK_ERROR', payload);
    },
};

export const getters = {
    networkError: (state) => {
        return state.networkError;
    },
};
