<template>
    <div>
        <h3
            class="mb-4 text-center font-barlo text-2xl font-medium uppercase tracking-wide text-gray-800"
        >
            Got it!
        </h3>
        <p class="text-center font-sans text-sm text-gray-500 md:mx-10">
            Etiam ut commodo velit, vel maximus quam. Cras eu suscipit ligula. Nulla pellentesque a
            eros ac varius.
        </p>

        <div class="my-8 flex justify-center">
            <div class="flex px-1 py-3">
                <!-- continue -->
                <button
                    type="button"
                    class="rounded-md border border-gray-300 bg-white px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-gray-700 shadow-sm transition duration-75 ease-linear hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                    @click="continueShopping"
                >
                    CONTINUE
                </button>
                <!-- /continue -->

                <!-- checkout -->
                <button
                    data-cy="checkout-button"
                    type="button"
                    class="ml-4 inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-barlo text-sm font-medium uppercase tracking-wider text-white shadow-sm transition duration-75 ease-linear hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60 md:ml-4"
                    @click.prevent="checkout"
                >
                    Checkout
                </button>
                <!-- /checkout -->
            </div>
        </div>
    </div>
</template>

<script lang="js">

export default {
  name: 'ContinueOrCheckout',
  methods: {
    async continueShopping() {
      await this.$store.dispatch('progress/setCurrentStep', 'BROWSING');
      await this.$store.dispatch('timer/setTimerVisibility', true);
    },
    async checkout() {
      await this.$store.dispatch('tab/setActiveTab', 'CART');
      await this.$store.dispatch('progress/setCurrentStep', 'CHECKOUT');
      await this.$store.dispatch('timer/setTimerVisibility', true);
    }
  }
}
</script>
